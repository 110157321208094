import { useLoaderData } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { loadIndexationReportStatsQuery } from "../../utils/api";
import { useEffect } from "react";
import Icon, { IconNames } from "../../components/Icon/Icon";
import BookLoader from "../../components/BookLoader/BookLoader";
import { useState } from "react";

interface ServerData {
	user_verified: boolean
	has_active_website: boolean,
	integration_done: boolean,
	indexation_done: boolean,
	plan_name: string
}

type Stats = {
    id: string
    icon_name: IconNames
    title: string
    count: number
}

export default function IndexationReport(){
    // ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;

    // ----------------------- STATES -----------------------
    const [indexationReportStats, setIndexationReportStats] = useState<Array<Stats>>([]);

    // ----------------------- EFFECTS -----------------------
    const { isLoading, error, data, refetch } = useQuery(loadIndexationReportStatsQuery());

    useEffect(() => {
		if (data) {
			setIndexationReportStats((data as any)['data']['index_stats_report']);
		}
	}, [data]);

    if (!pageData.indexation_done) {
        return (
            <BookLoader words={["posts", "articles", "blogs", "pages", "sitemap"]} />
        )
    } else if (isLoading){
        return (
			<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
			</p>
		)
    } else {
        return (
            <>
                <div className={"indexation-report-container"}>
                    {indexationReportStats.map((stat) => (
                        <div className={"card mt-2"} key={stat.id}>
                            <div className={"card-content"}>
                                <div className={"content"}>
                                    <div className={"usage-stats-container"}>
                                        <p className={"usage-stats-item--title"}>
                                            <Icon iconName={stat.icon_name} />&nbsp;&nbsp;&nbsp;{stat.title}
                                        </p>
                                        <h4 style={{"fontSize": "1.5rem"}} className={"ml-4 mt-2"}>{stat.count}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }
}