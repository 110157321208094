import { useRef, useState, useEffect } from "react";
import { useQuery, useMutation } from '@tanstack/react-query'
import { useLoaderData } from "react-router-dom";
import { ColumnDef, createColumnHelper, RowData, RowModel } from "@tanstack/react-table";
import AbunTable, { IndeterminateCheckbox } from "../../components/AbunTable/AbunTable";
import GenericButton from "../../components/GenericButton/GenericButton";
import { loadAllPageQuery, sendPageForIndexingMutation } from "../../utils/api";
import Icon from "../../components/Icon/Icon";
import AbunModal from "../../components/AbunModal/AbunModal";
import { Player } from "@lottiefiles/react-lottie-player";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import BookLoader from "../../components/BookLoader/BookLoader";

type AllPages = {
    url: string,
    index: boolean
}

interface ServerData {
	user_verified: boolean
	has_active_website: boolean,
	integration_done: boolean,
	indexation_done: boolean,
	plan_name: string
}

export default function AllPages(){
    // ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [50, 100, 500, 1000];

    // ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;

    // ----------------------- STATES -----------------------
    const [tableData, setTableData] = useState<Array<AllPages>>([]);
    const [searchText, setSearchText] = useState("");
    const [selectedPages, setSelectedPages] = useState<Array<string>>([]);
    const [requestModalActive, setRequestModalActive] = useState(false);
    const [modalText, setModalText] = useState("");

    // ----------------------- EFFECTS -----------------------
    const { isLoading, error, data, refetch } = useQuery(loadAllPageQuery());
    const sendPageForIndexing = useMutation(sendPageForIndexingMutation);

    useEffect(() => {
		if (data) {
			setTableData((data as any)['data']['all_pages']);
		}
	}, [data]);

    useEffect(() => {
		if (window.location.search) {
			const searchParams = new URLSearchParams(window.location.search);
			const url = searchParams.get("url");
			setSearchText(url?.toLowerCase() || "");
		}
	}, []);

    // ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

    if (!pageData.indexation_done) {
        return (
            <BookLoader words={["posts", "articles", "blogs", "pages", "sitemap"]} />
        )
    } else if (isLoading){
        return (
			<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
			</p>
		)
    } else {
        // ================== Generate table data and render AbunTable component ==================
        const columnHelper = createColumnHelper<AllPages>();
    
        const columnDefs: ColumnDef<any, any>[] = [
            columnHelper.accessor((row: AllPages) => row.url, {
                id: 'checkbox',
                header: ({ table }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: table.getIsAllRowsSelected(),
                            indeterminate: table.getIsSomeRowsSelected(),
                            onChange: table.getToggleAllRowsSelectedHandler(),
                        }}
                    />
                ),
                cell: ({ row }) => (
                    <IndeterminateCheckbox
                        {...{
                            checked: row.getIsSelected(),
                            disabled: !row.getCanSelect(),
                            indeterminate: row.getIsSomeSelected(),
                            onChange: row.getToggleSelectedHandler(),
                        }}
                        name={"pagesSelection"}
                        value={row.original.url}
                    />
                ),
                enableGlobalFilter: true,
                enableSorting: false,
            }),
        
            columnHelper.accessor((row: AllPages) => row.url, {
                id: 'pageUrl',
                header: "URL",
                cell: info => info.getValue(),
                enableGlobalFilter: true,
                enableSorting: false,
            }),
    
            columnHelper.accessor((row: AllPages) => row.index, {
                id: 'index',
                header: "Indexed",
                cell: info => {
                    let displayText: string;
                    let btnColor: string;

                    if (info.row.original.index){
                        displayText = 'YES';
					    btnColor = 'success';
                    } else {
                        displayText = 'NO';
					    btnColor = 'warning';
                    }
                    return <button
                                className={`button is-small more-rounded-borders ${`is-${btnColor}`} custom-btn`}
                                disabled>{displayText}</button>

                },
                enableGlobalFilter: false,
            }),
    
            columnHelper.accessor((row: AllPages) => row.index, {
                id: 'index_page',
                header: () => "Index Page",
                cell: info => {
                    return <GenericButton text={"Index"}
                        type={"primary"}
                        width={"100px"}
                        outlined={true}
                        additionalClassList={["is-small", "more-rounded-borders"]}
                        clickHandler={() => {
                            setModalText("Processing request. Please wait...");
                            setRequestModalActive(true);
                            successAlertRef.current?.show("");
                            failAlertRef.current?.show("");

                            sendPageForIndexing.mutate([info.row.original.url], {
                                onSuccess: (res) => {
                                    let success: string = res['data']['success'];
                                    let total_pages_sent: number = res['data']['total_pages'];
                                    setRequestModalActive(false);

                                    if (success){
                                        if (total_pages_sent === 1){
                                            successAlertRef.current?.show(`Page sent for indexing successfully!`);
                                            refetch().then(() => {});
                                        } else {
                                            failAlertRef.current?.show(
                                                "Oops! Something went wrong. Please try again after some time or contact us if the issue persists."
                                            );
                                        }
                                    } else {
                                        let message: string = res['data']['message'];
                                        failAlertRef.current?.show(message);
                                    }
                                },
                                onError: () => {
                                    setRequestModalActive(false);
                                    failAlertRef.current?.show(
                                        "Oops! Something went wrong. Please try again after some time or contact us if the issue persists."
                                    );
                                }
                            })
                        }} />
                },
                enableGlobalFilter: false,
                enableSorting: false,
                meta: {
                    align: 'center',
                }
            }),
        ]

        function selectedRowsSetter(rowModel: RowModel<RowData>){
            setSelectedPages([]);
            rowModel.rows.forEach((row) => {
                const selectedPage = (row.original as AllPages);
                setSelectedPages((prevPage) => [...prevPage, selectedPage.url]);
            })
        }

        return (
            <>
                {/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
				<AbunModal active={requestModalActive}
					headerText={""}
					closeable={false}
					hideModal={() => {
						setRequestModalActive(false)
					}}>
					<Player
						autoplay
						loop
						src="https://assets5.lottiefiles.com/private_files/lf30_tcux3hw6.json"
						style={{ height: '300px', width: '300px' }}
					>
					</Player>
					<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
				</AbunModal>

                <div className="card mt-4">
                    <div className="card-content">
                        <div className="content is-flex is-flex-direction-column">
                            <AbunTable
                                tableContentName={"ALL Pages"}
                                id={"indexation-all-pages"}
                                searchText={searchText}
                                tableData={tableData}
                                columnDefs={columnDefs}
                                pageSizes={pageSizes}
                                initialPageSize={pageSizes[1]}
                                enableSorting={true}
                                noDataText={"No Pages Found"}
                                searchboxPlaceholderText={"Search for Pages..."}
                                rowCheckbox={true}
                                selectedRowsSetter={selectedRowsSetter}
                                // buttons={ !selectedPages.length ? [] : [
                                //     {
                                //         text: "Index Selected URLs",
                                //         type: "success",
                                //         clickHandler: () => {
                                //             setRequestModalActive(true);
                                //             successAlertRef.current?.show("");
                                //             failAlertRef.current?.show("");

                                //             sendPageForIndexing.mutate(selectedPages, {
                                //                 onSuccess: (res) => {
                                //                     setRequestModalActive(false);
                                //                     let total_pages: number = res['data']['total_pages'];
                                //                     let success: string = res['data']['success'];

                                //                     if (success){
                                //                         if (total_pages !== 0){
                                //                             successAlertRef.current?.show(`${total_pages} pages sent for indexing successfully!`);
                                //                             refetch().then(() => {});
                                //                         } else {
                                //                             setRequestModalActive(false);
                                //                             failAlertRef.current?.show(
                                //                                 "Oops! Something went wrong. Please try again after some time or contact us if the issue persists."
                                //                             );
                                //                         }
                                //                     } else {
                                //                         let message: string = res['data']['message'];
                                //                         failAlertRef.current?.show(message); 
                                //                     }
                                //                 },
                                //                 onError: () => {
                                //                     setRequestModalActive(false);
                                //                     failAlertRef.current?.show(
                                //                         "Oops! Something went wrong. Please try again after some time or contact us if the issue persists."
                                //                     );
                                //                 }
                                //             })
                                //         },
                                //     }
                                // ]}
                                
                                />
                        </div>
                        <div className="alerts">
                            <SuccessAlert ref={successAlertRef} />
                            <ErrorAlert ref={failAlertRef} />
                        </div>
                    </div>
                </div>
            </>
        )
    }

}
