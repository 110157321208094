import {Link} from "react-router-dom";
import {useState} from "react";
import {pageURL} from "../routes";
import {forgotPasswordMutation} from "../../utils/api";
import {useMutation} from "@tanstack/react-query";


export default function ForgotPassword() {
	// ------------------------- STATES -------------------------
	const [emailInput, setEmailInput] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");

	// ------------------------- MUTATIONS -------------------------
	const forgotPassword = useMutation(forgotPasswordMutation);

	// =============================================================
	// ------------------------- MAIN CODE -------------------------
	// =============================================================
	function emailIsValid(value: string): boolean {
		return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value);
	}

	function submitEmail() {
		setErrorMsg("");
		setSuccessMsg("");
		if (emailIsValid(emailInput)) {
			forgotPassword.mutate(emailInput, {
				onSuccess: () => {
					setSuccessMsg("An email containing the link to reset your account password has " +
						"been sent to this email id if it exists.");
				},
				onError: () => {
					setErrorMsg("Oops! Something went wrong :( Please try again or contact us for support.");
				}
			});
		} else {
			setErrorMsg("Please enter a valid email id (ex. john@example.com)");
		}
	}

	return (
		<div className="login-container">
			<h1 className="heading-text has-text-black epilogue">Forgot Password?</h1>
			<h2 className="subtitle-text has-text-black bricolage">
				No worries! Just enter your account email id and we'll send a link to reset your password.
			</h2>
			<div className="card login-card mt-5">
				<div className="card-content">
					<div className="content has-text-centered">
						{/* ------------------ EMAIL ID ------------------ */}
						<div className="field mt-5">
							<div className="control">
								<input value={emailInput}
											 className="input is-medium"
											 type="email"
											 name="password"
											 placeholder="Email ID" onChange={(e) => {
									setEmailInput(e.target.value)
								}}/>
							</div>
						</div>
						<button className="button has-text-black login-btn-border is-large is-responsive mt-5"
										style={{borderRadius: "8px"}}
										disabled={forgotPassword.isLoading}
										onClick={submitEmail}>
							Send Password Reset Link
						</button>
						<p className="has-text-danger is-small has-text-centered mt-4">{errorMsg}</p>
						<p className="has-text-success is-small has-text-centered mt-4">{successMsg}</p>
					</div>
				</div>
			</div>

			<p className="has-text-black has-text-centered bricolage is-size-4 mt-6">
				<Link to={pageURL['login']} className="has-text-black is-underlined">Remember Your Password?</Link>
				&nbsp;&nbsp;|&nbsp;&nbsp;
				<Link to={pageURL['signup']} className="has-text-black is-underlined">Create New Account</Link>
			</p>
		</div>
	)
}
