import {useEffect, useRef} from "react";

import './GenericButton.scss'
import spinnerIcon from "../../assets/images/icons/spinner.svg";
import Icon, {IconNames} from "../Icon/Icon";

interface GenericButtonProps {
	text: string
	type: "primary" | "success" | "warning" | "danger" | "secondary"
	width?: string
	height?: string
	icon?: IconNames
	iconWidth?: string
	iconHeight?: string
	outlined?: boolean
	additionalClassList?: string[]
	clickHandler: () => void
	disable?: boolean
	title?: string
}

/**
 * Button which makes an API request to REACT_APP_DRF_DOMAIN on provided `path` resource.
 * Only success responses are sent back to callback function
 *
 * @param props - text, type, path, requestType, data?, additionalClassList?, callback
 */
export default function GenericButton(props: GenericButtonProps) {
	// --------------- Refs ---------------
	const buttonRef = useRef<HTMLButtonElement>(null);
	const spinnerRef = useRef<HTMLImageElement>(null);

	// --------------- Effects ---------------
	// For disabling button
	useEffect(() => {
		if (props.disable) {
			buttonRef.current?.setAttribute('disabled', "1");
		} else {
			buttonRef.current?.removeAttribute('disabled');
		}
	}, [props.disable]);

	function startSpinner() {
		spinnerRef.current?.classList.add('show');
	}

	function stopSpinner() {
		spinnerRef.current?.classList.remove('show');
	}

	/**
	 * This is just a wrapper that wrapps the prop callback function to start
	 * and stop the spinners. The callback function can be async or non-async.
	 */
	async function buttonEventHandler() {
		startSpinner();
		try {
			await props.clickHandler();
			stopSpinner();
		} catch (err) {
			console.error(err);
			stopSpinner();
		}
	}

	let buttonClassList = ['button', `is-${props.type}`];
	if (props.outlined) buttonClassList.push('is-outlined');
	if (props.additionalClassList) buttonClassList.push(...props.additionalClassList);

	let iconClasses = ["mr-4"];
	if (!props.outlined) iconClasses.push("icon-white");

	return (
		<button className={buttonClassList.join(' ')}
						type="button"
						ref={buttonRef}
						style={{width: props.width, height: props.height}}
						onClick={buttonEventHandler}
						title={props.title}
						>
			{props.icon ? <Icon iconName={props.icon} additionalClasses={iconClasses} /> : ""}
			{props.text}
			<img src={spinnerIcon} alt="*" className="white-spinner ml-4" ref={spinnerRef}/>
		</button>
	)
}
