import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export default function BasicDateTimePicker(props: {
    label: string,
    defaultDate: Dayjs | null,
    isOpen: boolean,
    className?: string
    onChange: (value: Dayjs | null) => void,
    onClose: () => void,
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker label={props.label}
                                value={props.defaultDate ? props.defaultDate : dayjs()}
                                open={props.isOpen}
                                className={props.className}
                                onChange={(scheduleDate) => props.onChange(scheduleDate)}
                                onClose={props.onClose}/>
            </DemoContainer>
        </LocalizationProvider>
    );
}