import greenCheck from "../../assets/images/icons/green-checkmark-circle.svg";
import {Link} from "react-router-dom";
import {pageURL} from "../routes";

import "./WebflowSuccess.scss";

export default function WebflowSuccess() {
	return (
		<div className={"webflow-success-container"}>
			<div className={"card"}>
				<div className={"card-content"}>
					<div className={"content success-card-content"}>
						<img src={greenCheck}
								 alt="green_checkmark"
								 width={92}
								 height={92}
								 className={"is-block ml-auto mr-auto mb-5"}/>
						<h2 className={"is-size-4 has-text-centered has-text-weight-bold"}>Webflow Integration Successful!</h2>
						<p className={"has-text-centered mt-3"}>
							You can now start posting generated articles on your site through Abun individually<br/>
							or by using our Auto Publish feature.
						</p>
						<div className={"block"}>
							<Link to={pageURL['settings'] + "?tab=integration"}
										className={"is-block mt-6 button is-primary"}>
								Configure Auto Publish
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
