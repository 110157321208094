import { useRef, useState, useEffect } from "react";
import { useQuery } from '@tanstack/react-query'
import { useLoaderData } from "react-router-dom";
import { ColumnDef, createColumnHelper} from "@tanstack/react-table";
import AbunTable from "../../components/AbunTable/AbunTable";
import { loadPagesSentForIndexingQuery } from "../../utils/api";
import Icon from "../../components/Icon/Icon";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import BookLoader from "../../components/BookLoader/BookLoader";

type AllPages = {
    url: string,
    date: string
}

interface ServerData {
	user_verified: boolean
	has_active_website: boolean,
	integration_done: boolean,
	indexation_done: boolean,
	plan_name: string
}

export default function AllPages(){
    // ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [50, 100, 500, 1000];

    // ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;

    // ----------------------- STATES -----------------------
    const [tableData, setTableData] = useState<Array<AllPages>>([]);
    const [searchText, setSearchText] = useState("");

    // ----------------------- EFFECTS -----------------------
    const { isLoading, error, data, refetch } = useQuery(loadPagesSentForIndexingQuery());

    useEffect(() => {
		if (data) {
			setTableData((data as any)['data']['all_pages']);
		}
	}, [data]);

    useEffect(() => {
		if (window.location.search) {
			const searchParams = new URLSearchParams(window.location.search);
			const url = searchParams.get("url");
			setSearchText(url?.toLowerCase() || "");
		}
	}, []);

    // ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

    if (!pageData.indexation_done) {
        return (
            <BookLoader words={["posts", "articles", "blogs", "pages", "sitemap"]} />
        )
    } else if (isLoading){
        return (
			<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
			</p>
		)
    } else {
        // ================== Generate table data and render AbunTable component ==================
        const columnHelper = createColumnHelper<AllPages>();
    
        const columnDefs: ColumnDef<any, any>[] = [
            columnHelper.accessor((row: AllPages) => row.url, {
                id: 'pageUrl',
                header: "URL",
                cell: info => info.getValue(),
                enableGlobalFilter: true,
                enableSorting: false,
            }),

            columnHelper.accessor((row: AllPages) => row.date, {
                id: 'date',
                header: "Page Sent On",
                cell: info => info.getValue(),
                enableGlobalFilter: true,
                enableSorting: false,
            }),
        ]

        return (
            <>
                <div className="card mt-4">
                    <div className="card-content">
                        <div className="content is-flex is-flex-direction-column">
                            <AbunTable
                                tableContentName={"Pages Sent For Indexing"}
                                id={"pages-sent-for-indexing"}
                                searchText={searchText}
                                tableData={tableData}
                                columnDefs={columnDefs}
                                pageSizes={pageSizes}
                                initialPageSize={pageSizes[1]}
                                enableSorting={true}
                                noDataText={"No Pages Found"}
                                searchboxPlaceholderText={"Search for Pages..."}
                                />
                        </div>
                        <div className="alerts">
                            <SuccessAlert ref={successAlertRef} />
                            <ErrorAlert ref={failAlertRef} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
