import greenCheck from "../../assets/images/icons/green-checkmark-circle.svg";
import { Link } from "react-router-dom";
import { pageURL } from "../routes";
import { useLoaderData } from "react-router-dom";

import "./GoogleSuccess.scss";

interface serverResponse {
    success: boolean
    message: string
}

export default function GoogleSuccess() {
    const pageData: serverResponse = useLoaderData() as serverResponse;

    function getIntegrationType() {
        let integrationType = localStorage.getItem('integration-type');

        if (integrationType) {
            // Split the string
            let integrationsTypeArray = integrationType.split("-");

            // Capitalize the 1st char of each word
            integrationsTypeArray = integrationsTypeArray.map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })

            // Join the array
            integrationType = integrationsTypeArray.join(" ");
        }

        return integrationType;
    }

    if (pageData.success) {
        const integrationType = getIntegrationType();

        return (
            <div className={"google-success-container"}>
                <div className={"card"}>
                    <div className={"card-content"}>
                        <div className={"content success-card-content"}>
                            <img src={greenCheck}
                                alt="green_checkmark"
                                width={92}
                                height={92}
                                className={"is-block ml-auto mr-auto mb-5"} />
                            <h2 className={"is-size-4 has-text-centered has-text-weight-bold"}>{integrationType ? integrationType : "Google"} Integration Successful!</h2>
                            {/* <p className={"has-text-centered mt-3"}>
                                You can now start posting generated articles on your site through Abun individually<br/>
                                or by using our Auto Publish feature.
                            </p> */}
                            <div className={"block"}>
                                <Link to={pageURL['keywordResearch']}
                                    className={"is-block mt-6 button is-primary"}>
                                    Keyword Research
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className={"google-success-container"}>
                <div className={"card"}>
                    <div className={"card-content"}>
                        <div className={"content success-card-content"}>
                            <p className={"has-text-centered"}>Oops! Something went wrong :(</p>
                            <p className={"has-text-danger has-text-centered mt-4"}>{pageData.message}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
