/* eslint-disable jsx-a11y/anchor-is-valid */
import "./Indexation.scss";
import { useEffect, useRef, useState } from "react";
import { useLoaderData, useSearchParams, Link } from "react-router-dom";
import AllPages from "./AllPages";
import PagesSentForIndexing from "./PagesSentForIndexing";
import IndexationReport from "./IndexationReport";
import PostedAbunArticles from "./PostedAbunArticles";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import MessageBox from "../../components/MessageBox/MessageBox";
import { pageURL } from "../routes";

type Tab = 'allPages' | 'pagesSentForIndexing' | 'indexationReport';

interface ServerData {
	user_verified: boolean
	has_active_website: boolean,
	integration_done: boolean,
	indexation_done: boolean,
	plan_name: string
}

export default function Indexation() {
	// ------------------------- QUERY PARAMETERS -----------------------
	const [searchParams] = useSearchParams();
	const tab = searchParams.get("tab");

	// ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;

	// ---------------------------- STATES ---------------------------
	const [currentTab, setCurrentTab] = useState<Tab | string>(
		tab || 'indexationReport'
	);

	// ---------------------------- REFS ----------------------------
	const errorAlertRef = useRef<any>(null);
	const successAlertRef = useRef<any>(null);

	// ---------------------------- EFFECTS ---------------------------
	useEffect(() => {
		document.title = "Indexation | Abun";
	}, []);


	function renderTab() {
		switch (currentTab) {
			case "allPages":
                return <AllPages />
			case "pagesSentForIndexing":
                return <PagesSentForIndexing />
			case "indexationReport":
                return <IndexationReport />
			case "abunArticles":
				return <PostedAbunArticles />
			default:
				return <IndexationReport />
		}
	}

	if (pageData.plan_name === "Trial"){
		return (
			<MessageBox title={"No Active Website"}
				type={"warning"}
				className="no-website-message-box"
				blackBodyText={true}
				style={{ maxWidth: "100%" }}>
				This feature is available only on <b>paid</b> plans. Upgrade to a <Link
				to={pageURL['manageSubscription']}><b>paid plan now.</b></Link>
			</MessageBox>
		)
	} else if (!pageData.has_active_website){
		return (
			<MessageBox title={"No Active GSC Account"}
				type={"warning"}
				className="no-website-message-box"
				blackBodyText={true}
				style={{ maxWidth: "100%" }}>
				You have not connected your GSC account with <b>Abun</b> yet.
				In order to use this feature you will have to connect your Google Search Console.
				<br />
				<br />
				The feature uses your Google Search Console to send indexing request to Google.
				<br />
				<br />
				Connect your <Link to={pageURL['connectWebsite']}><b>GSC account now.</b></Link>
			</MessageBox>
		)
	} else if (!pageData.integration_done){
		return (
			<MessageBox title={"GSC Not Connected"}
				type={"warning"}
				className="no-website-message-box"
				blackBodyText={true}
				style={{ maxWidth: "100%" }}>
				You have not connected your GSC account with <b>Abun</b> yet. Connect your <Link
					to={"/settings?tab=integration"}><b>GSC account now.</b></Link>
			</MessageBox>
		)
	} else {
		return (
			<>
				{!pageData.indexation_done && 
					<MessageBox title={"Indexation Is Processing"}
						type={"warning"}
						className="no-website-message-box"
						blackBodyText={true}
						style={{ maxWidth: "100%" }}>
						We are currently in the process of indexing your website pages. We will email you once the process is completed!
					</MessageBox>
				}
				<div className="tabs custom-tabs w-100">
					<ul>
						{/* Indexation Report */}
						<li className={currentTab === 'indexationReport' ? "is-active" : ""}
								onClick={() => setCurrentTab("indexationReport")}>
							<a>Indexation Report</a>
						</li>
						{/* All Pages */}
						<li className={currentTab === 'allPages' ? "is-active" : ""}
								onClick={() => setCurrentTab("allPages")}>
							<a>All Pages</a>
						</li>
						{/* Posted Abun Pages */}
						<li className={currentTab === 'abunArticles' ? "is-active" : ""}
								onClick={() => setCurrentTab("abunArticles")}>
							<a>Abun Articles</a>
						</li>
						{/* Pages sent for indexing */}
						<li className={currentTab === 'pagesSentForIndexing' ? "is-active" : ""}
								onClick={() => setCurrentTab("pagesSentForIndexing")}>
							<a>Pages Sent For Indexing</a>
						</li>
					</ul>
				</div>
				<div className="tab-content w-100">
					{renderTab()}
				</div>
				<ErrorAlert ref={errorAlertRef}/>
				<SuccessAlert ref={successAlertRef}/>
			</>
		)
	}
}
