import { MutableRefObject, useState, Dispatch, SetStateAction, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { saveSettingsMutation } from "../../utils/api";

export type FeatureImageTemplates = {
	template_id: string,
	sample_image_url: string
}

export type KeywordStrategies = 'volume' | 'cpc' | 'competition';
export type ImageSource = 'no_image' | 'google' | 'unsplash' | 'ai_image_generation';

interface ImageSettingsProps {
	errorAlertRef: MutableRefObject<any>
	successAlertRef: MutableRefObject<any>
	featuredImageTemplates: Array<FeatureImageTemplates>
	featureImageHasText: boolean | undefined
	selectedTemplate: string
	generateBannerbearFeaturedImage: boolean
	images_file_format: string
	keyword_strategy: KeywordStrategies
	image_source: ImageSource
	aiGeneratedImageStylePreference: string
	setFeaturedImageTemplates: Dispatch<SetStateAction<Array<FeatureImageTemplates>>>
	setSelectedTemplate: Dispatch<SetStateAction<string>>
	setfeatureImageHasText: Dispatch<SetStateAction<boolean | undefined>>
	setGenerateBannerbearFeaturedImage: Dispatch<SetStateAction<boolean>>
	updatePageData: () => void
	setUnsavedChanges: Dispatch<SetStateAction<boolean>>
}

export default function ImageSettings(props: ImageSettingsProps) {
	const {
		selectedTemplate,
		featureImageHasText,
		featuredImageTemplates,
		generateBannerbearFeaturedImage,
		setfeatureImageHasText,
		setSelectedTemplate
	} = props;

	// --------------------- STATES ---------------------
	const [
		imageSource,
		setImageSource
	] = useState<ImageSource>(props.image_source);

	const [
		aiGeneratedImageStylePreference,
		setAiGeneratedImageStylePreference
	] = useState<string>(props.aiGeneratedImageStylePreference);

	const [
		imageFilesFormat,
		setImagesFileFormat
	] = useState<string>(props.images_file_format);

	const [haveUnsavedChanges, setHaveUnsavedChanges] = useState<boolean>(false);

	// ---------------------------- MUTATIONS ----------------------------
	const saveSettings = useMutation(saveSettingsMutation);


	// ----------------------------- FUNCTIONS ---------------------------

	function changeFeatureImageTemplate(newValue: string) {
		if (newValue === selectedTemplate) return;
		setHaveUnsavedChanges(true);
		props.errorAlertRef.current?.close();
		if (newValue === "no_feature_image") {
			document.querySelector(".feature-image-templates")?.classList.add("is-hidden");
		} else {
			setSelectedTemplate(newValue);
		}
	}

	function changeFeatureImageTextOption(newValue: boolean) {
		setHaveUnsavedChanges(true);
		props.errorAlertRef.current?.close();
		setfeatureImageHasText(newValue);
		// changing the template to second on changing to without text option if the first template was selected with text
		if (newValue === false && selectedTemplate === "ok0l2K5mppOLZ3j1Yx") {
			changeFeatureImageTemplate(featuredImageTemplates[1].template_id);
		}
		props.setGenerateBannerbearFeaturedImage(true);
	}

	function changeGenerateFeaturedImage(newValue: boolean) {
		props.errorAlertRef.current?.close();
		if (newValue === false) {
			document.querySelector(".feature-image-templates")?.classList.add("is-hidden");
		} else {
			document.querySelector(".feature-image-templates")?.classList.remove("is-hidden");
		}
		props.setGenerateBannerbearFeaturedImage(newValue);
	}

	function changeImageFileFormat(newValue: string) {
		props.errorAlertRef.current?.close();
		setImagesFileFormat(newValue);
	}

	function changeImageSource(newValue: ImageSource) {
		props.errorAlertRef.current?.close();
		if (newValue === imageSource) return;
		setImageSource(newValue);
	}

	function saveAllImageSettings() {
		// validate the settings
		// if with text is selected but no template is selected or ai generated image is selected
		if (featureImageHasText && (selectedTemplate === "" || selectedTemplate === "AI-Generated-Image")) {
			props.errorAlertRef.current?.show("Please select a feature image template.");
			setTimeout(() => {
				try {
					if (props.errorAlertRef.current) {
						props.errorAlertRef.current.close();
					}
				} catch (e) {}
			}, 5000);
			return;
		}

		// save all image settings
		saveSettings.mutate({
			settingsToSave: [
				{ settingName: 'feature_image', settingValue: selectedTemplate },
				{ settingName: 'template_text', settingValue: featureImageHasText },
				{ settingName: 'generate_bannerbear_featured_image', settingValue: generateBannerbearFeaturedImage },
				{ settingName: 'images_file_format', settingValue: imageFilesFormat },
				{ settingName: 'image_source', settingValue: imageSource },
				{ settingName: 'ai_generated_image_style', settingValue: aiGeneratedImageStylePreference }
			]
		}, {
			onSuccess: () => {
				props.updatePageData();
				try {
					props.setUnsavedChanges(false);
					setHaveUnsavedChanges(false);
					props.successAlertRef.current.show("Settings updated successfully!");
					setTimeout(() => {
                        try {
                            if (props.successAlertRef.current) {
                                props.successAlertRef.current.close();
                            }
                        } catch (e) {}
					}, 3000);
				} catch (e) {}
			},
			onError: () => {
				props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
				setTimeout(() => {
					try {
						if (props.errorAlertRef.current) {
							props.errorAlertRef.current.close();
						}
					} catch (e) {}
				}, 5000);
			}
		});
	}

	// on tab change, check if there are unsaved changes
	useEffect(() => {
		const hasUnsavedChanges = imageSource !== props.image_source ||
			imageFilesFormat !== props.images_file_format ||
			haveUnsavedChanges;
		props.setUnsavedChanges(hasUnsavedChanges);
	}, [imageSource, imageFilesFormat, haveUnsavedChanges, props]);

	// =====================================================
	// --------------------- MAIN CODE ---------------------
	// =====================================================
	return (
		<>
			<div className="card setting-sections p-5">
				{/* -=-=-=-=-=-=-=-=-=-=-= Text or Without Text -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section mb-6"}>
					<h5 className={"settings-section-title"}>
						Do you want featured image with text or without text?
					</h5>
					<div className="is-flex control mt-4">
						<div className="mr-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"template-text"}
									checked={featureImageHasText === false && generateBannerbearFeaturedImage === true}
									onChange={() => changeFeatureImageTextOption(false)} />
								<span className={"settings-radio-item-title"}>Without Text</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"template-text"}
									checked={featureImageHasText === true && generateBannerbearFeaturedImage === true}
									onChange={() => changeFeatureImageTextOption(true)} />
								<span className={"settings-radio-item-title"}>With Text</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"template-text"}
									checked={generateBannerbearFeaturedImage === false}
									onChange={() => changeGenerateFeaturedImage(false)} />
								<span className={"settings-radio-item-title"}>No Feature Image</span>
							</label>
						</div>
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= Feature Image Templates -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={`settings-section feature-image-templates ${generateBannerbearFeaturedImage === false ? 'is-hidden' : ''}`}>
					<h5 className={"settings-section-title"}>
						Which feature image template you want to use in your articles?
					</h5>
					<div className={"templates-container is-flex is-align-items-center mt-5"}>
						<div className={`control template mt-1 ${featureImageHasText ? "hidden-template" : ""}`}>
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"feature-image"}
									checked={selectedTemplate === "AI-Generated-Image"}
									onChange={() => changeFeatureImageTemplate("AI-Generated-Image")} />
								<span className={"settings-radio-item-title"}>
									<img src={"https://res.cloudinary.com/diaiivikl/image/upload/v1709385716/ai-generated-image_rv1ice.jpg"}
										alt="featured_image"
										className={`${selectedTemplate === "AI-Generated-Image" ? "selected-image" : ""}`}
										height={"auto"} />
								</span>
							</label>
						</div>
						{featuredImageTemplates?.map((template, index) => (
							// hiding blank template without text, 7wpnPQZzKKOm5dOgxo and yKBqAzZ9xwB0bvMx36
							<div key={index} className={`control template mt-1 
							${featureImageHasText === false && (template.template_id === "ok0l2K5mppOLZ3j1Yx" ||
								template.template_id === "7wpnPQZzKKOm5dOgxo" ||
								template.template_id === "yKBqAzZ9xwB0bvMx36")
							? "hidden-template" : ""}`}>
								<label className="radio is-flex is-align-items-center">
									<input type="radio"
										className={`mr-4`}
										name={"feature-image"}
										checked={selectedTemplate === template.template_id}
										onChange={() => changeFeatureImageTemplate(template.template_id)} />
									<span className={"settings-radio-item-title"}>
										<img src={template.sample_image_url}
											alt="featured_image"
											className={`${selectedTemplate === template.template_id ? "selected-image" : ""}`}
											height={"auto"} />
									</span>
								</label>
							</div>
						))}
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= Image Source -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section mt-6"}>
					<h5 className={"settings-section-title"}>
						Which image source do you want to use in your articles?
					</h5>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'ai_image_generation'}
								onChange={() => changeImageSource('ai_image_generation')} />
							<span className={"settings-radio-item-title"}>AI Generated</span>
						</label>
						<p className={"settings-radio-item-description"}>
							AI Generated images will be used in the Article.
						</p>
					</div>
					{/* <div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'unsplash'}
								onChange={() => changeImageSource('unsplash')} />
							<span className={"settings-radio-item-title"}>Unsplash</span>
						</label>
						<p className={"settings-radio-item-description"}>
							Unsplash is a website dedicated to proprietary stock photography.
						</p>
					</div> */}
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'google'}
								onChange={() => changeImageSource('google')} />
							<span className={"settings-radio-item-title"}>Google Images</span>
						</label>
						<p className={"settings-radio-item-description"}>
							Get images from Google. Using this option might require you to get the necessary permission(s)
							from the photo owner before using them in your article.
						</p>
					</div>
					<div className="control mt-5">
						<label className="radio">
							<input type="radio"
								className={"mr-4"}
								name={"image-source"}
								checked={imageSource === 'no_image'}
								onChange={() => changeImageSource('no_image')} />
							<span className={"settings-radio-item-title"}>No Image</span>
						</label>
						<p className={"settings-radio-item-description"}>
							No images will be used in the Article.
						</p>
					</div>
				</div>

				{/* -=-=-=-=-=-=-=-=-=-=-= AI Image Style Preference -=-=-=-=-=-=-=-=-=-=-= */}
				{/* {imageSource === 'ai_image_generation' &&
					<div className={'settings-section mt-6 ai-image-style-preference'}>
						<h5 className={"settings-section-title"}>
							AI Generated Image Style Preference
						</h5>
						<div className="control mt-1">
							<p className={"settings-radio-item-description"}>
								Please enter the style of the image you want to be generated by the AI. For example: "Illustration", "Realistic", "Cartoon", "Anime", "Abstract", etc(max 50 characters).
							</p>
							<input type="text"
								className={"input"}
								placeholder={"Enter the style of the image, max 50 characters"}
								value={aiGeneratedImageStylePreference.toString()}
								onChange={(e) => setAiGeneratedImageStylePreference(e.target.value)} />
						</div>
					</div>} */}

				{/* -=-=-=-=-=-=-=-=-=-=-= Image File Format -=-=-=-=-=-=-=-=-=-=-= */}
				<div className={"settings-section mt-6"}>
					<h5 className={"settings-section-title"}>
						Images File Format:
					</h5>
					<div className="is-flex control mt-4">
						<div className="mr-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imageFilesFormat === "webp"}
									onChange={() => changeImageFileFormat("webp")} />
								<span className={"settings-radio-item-title"}>WebP</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imageFilesFormat === "png"}
									onChange={() => changeImageFileFormat("png")} />
								<span className={"settings-radio-item-title"}>PNG</span>
							</label>
						</div>
						<div className="ml-4">
							<label className="radio is-flex is-align-items-center">
								<input type="radio"
									className={"mr-4"}
									name={"image-format"}
									checked={imageFilesFormat === "jpeg"}
									onChange={() => changeImageFileFormat("jpeg")} />
								<span className={"settings-radio-item-title"}>JPG</span>
							</label>
						</div>
					</div>
				</div>
			</div>

			{/* -=-=-=-=-=-=-=-=-=-=-= Save All Settings Button -=-=-=-=-=-=-=-=-=-=-= */}
			<div className="save-changes-section mt-4">
				<button
					className={`button is-primary ${saveSettings.isLoading ? 'is-loading' : ''}`}
					onClick={saveAllImageSettings}
				>
					Save Changes
				</button>
			</div>
		</>
	)
}
