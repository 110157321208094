import "./ResetPassword.scss";

import {Link, useParams} from "react-router-dom";
import Input from "../../components/Input/Input";
import {useEffect, useRef, useState} from "react";
import GenericButton from "../../components/GenericButton/GenericButton";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import abunLogo from "../../assets/images/branding/abun_blue_text_logo.png";
import {useMutation} from "@tanstack/react-query";
import {resetPasswordMutation} from "../../utils/api";
import {pageURL} from "../routes";
import greenCheck from "../../assets/images/icons/green-checkmark-circle.svg"


export default function ResetPassword() {
	// ----------------------- URL PARAMETERS -----------------------
	const {encryptedEmail, resetUID} = useParams();

	// ----------------------- STATES -----------------------
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

	// ----------------------- REFS -----------------------
	const errorAlertRef = useRef<any>(null);

	// ----------------------- MUTATIONS -----------------------
	const resetPassword = useMutation(resetPasswordMutation);

	useEffect(() => {
		document.title = "Reset Password | Abun"
	}, []);

	// =========================================================
	// ----------------------- MAIN CODE -----------------------
	// =========================================================
	function resetPasswordButtonHandler() {
		errorAlertRef.current?.close();
		if (newPassword.length < 6) {
			errorAlertRef.current?.show("Please keep a password with minimum 6 characters.");
		} else if (newPassword !== confirmPassword) {
			errorAlertRef.current?.show("Confirm Password value does not match New Password value. " +
				"Please enter same password in both the fields.");
		} else {
			if (encryptedEmail && resetUID) {
				resetPassword.mutate({encryptedEmail: encryptedEmail, resetUID: resetUID, newPassword: newPassword}, {
					onSuccess: () => {
						setResetPasswordSuccess(true);
					},
					onError: (error) => {
						console.error(error);
						errorAlertRef.current?.show("Oops! Something went wrong. Please try again or contact us for support.");
					}
				})
			} else {
				errorAlertRef.current?.show("Missing required data. Please make sure you are using the correct link.");
			}
		}
	}

	if (resetPasswordSuccess) {
		return (
			<div className={"reset-password-container"}>
				<div className={"card reset-password-card"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<img src={greenCheck} alt="green_checkmark" width={92} height={92} className={"is-block ml-auto mr-auto mb-5"}/>
							<h2 className={"is-size-4 has-text-centered"}>Password Reset Successful!</h2>
							<p className={"has-text-centered"}>Try logging in with the new credentials.</p>
							<Link to={pageURL['login']}
										className={"is-block mt-6 ml-auto mr-auto button is-primary"}>
								Go to Login
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	} else {
		return (
			<div className={"reset-password-container"}>
				<h2 className={"is-size-1 has-text-centered mb-3 has-text-white"}>
					Reset Password
				</h2>
				<p className={"mb-6 has-text-white has-text-centered is-size-5"}>Please enter your new password. Minimum 6
					characters long.</p>
				<div className={"card reset-password-card"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<img src={abunLogo} width="128" height="auto" alt="Abun Logo" className={"is-block ml-auto mr-auto"}/>
							<label className={"label mt-5"}>
								New Password:
								<Input value={newPassword}
											 type={"password"}
											 placeholder={"Min. 6 characters"}
											 onChange={setNewPassword}/>
							</label>
							<label className={"label mt-5"}>
								Confirm Password:
								<Input value={confirmPassword}
											 type={"password"}
											 onChange={setConfirmPassword}/>
							</label>
							<GenericButton text={"Reset Password"}
														 type={"success"}
														 additionalClassList={["mt-6", "ml-auto", "mr-auto", "is-block"]}
														 clickHandler={resetPasswordButtonHandler}/>
						</div>
					</div>
				</div>
				<ErrorAlert ref={errorAlertRef}/>
			</div>
		)
	}
}
