import "./ResearchedKeywords.scss";
import { useState, useEffect, useRef, MutableRefObject } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Link } from "react-router-dom";
import {
	getAutomationProjects,
	getKeywordProjects,
	saveAutomationProjectMutation,
	getAutomationProjectData,
	deleteAutomationProject,
	updateAutomationProjectMutation,
	getGeneratedArticlesForAutomationProject
} from "../../utils/api";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import AbunTable from "../../components/AbunTable/AbunTable";
import AbunButton from "../../components/AbunButton/AbunButton";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import Icon from "../../components/Icon/Icon";
import {
	FormControl,
	MenuItem,
	Select,
	TextField,
	Switch,
	ToggleButton,
	ToggleButtonGroup
} from '@mui/material';

interface AutomationProjectData {
	projectName: string;
	projectId: string;
	automationState: string;
}

interface KeywordsProjectData {
	projectName: string;
	totalKeywords: number;
	totalTrafficVolume: number;
	projectId: string;
	locationIsoCode: string;
}

interface AutomationProjectProps {
	projectId?: string;
	handleBackBtnClick: () => void;
	allIntegrations: Array<string>
}

interface EditAutomationProjectProps {
	projectName: string;
	projectId: string;
	selectedKeywordProjectName: string,
	trafficRange: Array<number>,
	articlesCount: number,
	frequency: string,
	integration: string,
	publishState: string,
	publishDays: Array<string>,
	publishTime: string,
	publishOnlyGeneratedArticles: boolean
	failAlertRef: MutableRefObject<null>;
	successAlertRef: MutableRefObject<null>;
	handleBackBtnClick: () => void;
}

interface RealTimeEstimationsProps {
	articlesCount: number;
	frequency: string;
	publishDays: Array<string>;
	publishState: string;
	publishTime: string;
	keywordsProjectId: string;
	KeywordTrafficRange: Array<number>;
	publishOnlyGeneratedArticles: boolean;
}

interface AutomationArticle {
	article_uid: string;
	title: string;
	keyword: string;
	keyword_volume: number;
}

interface KeywordProjectKeyword {
	keyword: string;
	volume: number;
}


export default function AddAutomation() {
	// ------------------ NON STATE CONSTANTS ------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// -------------------------- STATES --------------------------
	const [tableData, setTableData] = useState<Array<AutomationProjectData>>([]);
	const [selectedPage, setSelectedPage] = useState("");
	const [selectedTableRow, setSelectedTableRow] = useState<AutomationProjectData | null>(null);
	const [allIntegrations, setAllIntegrations] = useState<Array<string>>([]);

	// ----------------------- QUERIES -----------------------
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getAutomationProjectsAPI'],
		queryFn: () => getAutomationProjects(),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});

	// ----------------------- EFFECTS -----------------------
	useEffect(() => {
		document.title = "AI Content Automation | Abun";
	}, []);

	useEffect(() => {
		if (data) {
			if (data.status === 200) {
				setTableData(data.data.automation_projects as Array<AutomationProjectData>);
				setAllIntegrations(data.data.all_integrations);
			} else {
				console.log("Error fetching data", data);
			}
		}
	}, [data, tableData]);

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// ------------------ TABLE COLUMN DEFS ------------------
	const columnHelper = createColumnHelper<AutomationProjectData>();
	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: AutomationProjectData) => row.projectName, {
			id: 'projectName',
			header: "Automation Project",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
		}),
		columnHelper.display({
			id: 'automationState',
			header: "State",
			cell: props => {
				return (
					<div className={"is-flex is-align-items-center is-justify-content-center"}>
						<span className={"ml-2"}>{props.row.original.automationState}</span>
					</div>
				);
			},
			enableGlobalFilter: false,
		}),
		columnHelper.display({
			id: 'actions',
			header: "Actions",
			cell: props => {
				return (
					<div className={"is-flex is-align-items-center is-justify-content-center"} style={{ gap: "0.5rem" }}>
						<button className={"button is-small no-border is-flex is-align-items-center is-justify-content-center"} onClick={(row) => {
							if (props.row.original.automationState.toLowerCase() === "on" || props.row.original.automationState.toLowerCase() === "publish") {
								failAlertRef.current?.show(
									`Automation Project is currently running on auto-publish mode. Please turn it off, before deleting.`
								);
								setTimeout(() => {
									failAlertRef.current?.close();
								}, 5000);
								return;
							} else if (props.row.original.automationState.toLowerCase() === "draft") {
								failAlertRef.current?.show(
									`Automation Project is currently running on draft mode. Please turn it off, before deleting.`
								);
								setTimeout(() => {
									failAlertRef.current?.close();
								}, 5000);
								return;
							} else if (props.row.original.automationState.toLowerCase().includes("pause")) {
								failAlertRef.current?.show(
									`Automation Project is currently paused. Please turn it off, before deleting.`
								);
								setTimeout(() => {
									failAlertRef.current?.close();
								}, 5000);
								return;
							} else if (props.row.original.automationState.toLowerCase() === "off") {
								deleteAutomationProject(props.row.original.projectId).then((data) => {
									if (data.status === 200) {
										successAlertRef.current?.show(
											`Automation Project deleted successfully!`
										);
										setTimeout(() => {
											successAlertRef.current?.close();
										}, 5000);
										refetch();
									}
								}).catch((error) => {
									failAlertRef.current?.show(
										`Error deleting Automation Project. Please try again. ${error.message}`
									);
									setTimeout(() => {
										failAlertRef.current?.close();
									}, 5000);
								});
							}
						}}>
							<Icon iconName={"trash"} />
						</button>
					</div>
				);
			},
			enableGlobalFilter: false,
		}),
	];


	// -------------------------- FUNCTIONS --------------------------
	function handleBackBtnClick() {
		setSelectedPage("");
		refetch();
	}

	return (
		<>
			{
				selectedPage === "" &&
				<div className={"keyword-research-container is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column"}>
					<div className={"is-flex is-justify-content-center is-align-items-center is-flex-direction-column"}>
						<h2 className={"is-size-4"}>Automate Content Creation & Publishing</h2>
					</div>

					<div className={"menu-btns-container automation-project"}>
						{/* Create New Automation Project button */}
						<div className={"menu-btns AI-keyword-research-btn"} onClick={() => {
							setSelectedPage("create-new-automation-project");
						}}>
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="-0.5 -0.5 24 24" height="24" width="24">
								<g>
									<path fill="#ffffff" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M19.608697916666667 3.3036193750000002c-0.24475833333333336 -1.1551366666666667 -1.273625 -1.9228095833333332 -2.4713020833333332 -1.8628370833333334 -1.4472270833333334 0.072464375 -3.7129187500000005 0.24069020833333335 -6.9046 0.62629 -3.1916812500000002 0.38559979166666664 -5.431450000000001 0.7616977083333334 -6.8534825 1.035876875 -1.1768477083333335 0.22689979166666668 -1.9864045833333333 1.216685625 -1.939911041666667 2.3957758333333334 0.05083479166666667 1.2890541666666666 0.18624250000000003 3.2381124999999997 0.5217597916666666 5.926860416666667 0.15179041666666668 1.2164125000000001 0.30610604166666666 2.276185416666667 0.4543697916666667 3.188997916666667" strokeWidth="1.5" />
									<path id="hover" stroke="#2859c5" strokeLinejoin="round" d="M2.60765375 19.146589583333334c0.10471229166666667 1.2196708333333333 1.0569122916666667 2.1305666666666667 2.2783129166666667 2.2130791666666667C6.3641958333333335 21.459479166666664 8.687004166666668 21.5625 11.979166666666668 21.5625c3.2921625 0 5.6149708333333335 -0.10302083333333334 7.0932 -0.20283125000000002 1.2213958333333335 -0.0825125 2.1735958333333336 -0.9934083333333333 2.27829375 -2.2130791666666667C21.45904791666667 17.884177083333334 21.5625 16.00210625 21.5625 13.416666666666668s-0.10345208333333335 -4.467510416666666 -0.21183958333333333 -5.729922916666667c-0.10469791666666667 -1.2196708333333333 -1.0568979166666668 -2.1305666666666667 -2.27829375 -2.2130791666666667C17.594137500000002 5.373854166666667 15.271329166666668 5.270833333333334 11.979166666666668 5.270833333333334c-3.2921625 0 -5.6149708333333335 0.10302083333333334 -7.0932 0.20283125000000002 -1.221400625 0.0825125 -2.1735958333333336 0.9934083333333333 -2.2783129166666667 2.2130791666666667C2.499271041666667 8.949156250000001 2.3958333333333335 10.831227083333333 2.3958333333333335 13.416666666666668s0.10343770833333334 4.467510416666666 0.21182041666666668 5.729922916666667Z" strokeWidth="1.5" />
									<path id="hover" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 9.104166666666668h-3.8333333333333335" strokeWidth="1.5" />
									<path id="hover" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 14.854166666666668h-3.8333333333333335" strokeWidth="1.5" />
									<path id="hover" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M18.6875 17.729166666666668H5.270833333333334" strokeWidth="1.5" />
									<path stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M9.104166666666668 11.979166666666668h-3.8333333333333335" strokeWidth="1.5" />
									<path fill="#ffffff" stroke="#2859c5" strokeLinejoin="round" d="M12.021045833333334 13.810781250000002c0.04120833333333333 0.8179375000000001 0.6627354166666667 1.4394645833333335 1.4806729166666668 1.4806729166666668C13.97638125 15.315364583333333 14.582910416666667 15.333333333333334 15.333333333333334 15.333333333333334c0.7504229166666667 0 1.3569520833333335 -0.01796875 1.8316145833333333 -0.04187916666666667 0.8179375000000001 -0.04120833333333333 1.4394645833333335 -0.6627354166666667 1.4806729166666668 -1.4806729166666668C18.66953125 13.33611875 18.6875 12.729589583333333 18.6875 11.979166666666668c0 -0.7504229166666667 -0.01796875 -1.3569520833333335 -0.04187916666666667 -1.8316145833333333 -0.04120833333333333 -0.8179375000000001 -0.6627354166666667 -1.4394645833333335 -1.4806729166666668 -1.4806729166666668C16.690285416666665 8.642968750000001 16.08375625 8.625 15.333333333333334 8.625c-0.7504229166666667 0 -1.3569520833333335 0.01796875 -1.8316145833333333 0.04187916666666667 -0.8179375000000001 0.04120833333333333 -1.4394645833333335 0.6627354166666667 -1.4806729166666668 1.4806729166666668C11.997135416666667 10.622214583333333 11.979166666666668 11.228743750000001 11.979166666666668 11.979166666666668c0 0.7504229166666667 0.01796875 1.3569520833333335 0.04187916666666667 1.8316145833333333Z" strokeWidth="1.5" />
								</g>
							</svg>
							<span className={"menu-btn-text"}>Create New Automation Project</span>
						</div>
					</div>

					<div className={"table-container"}>
						<AbunTable tableContentName={"Automation Projects"}
							tableData={tableData}
							tableName="Your Content Automation Projects"
							columnDefs={columnDefs}
							pageSizes={pageSizes}
							initialPageSize={pageSizes[6]}
							noDataText={"No Automation Projects available"}
							searchboxPlaceholderText={"Search Automation Projects"}
							handleRowClick={(row) => {
								setSelectedTableRow(row);
								setSelectedPage("edit-auto-project")
							}} />
					</div>
				</div>
			}

			{
				selectedPage === "create-new-automation-project" &&
				<div className={"keyword-project-container is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column"}>
					<div className={"keyword-project-header"}>
						<svg className={"back-btn"} onClick={handleBackBtnClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
							<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
						</svg>
						<h3 className={"is-size-3"}>
							Create New Automation Project
						</h3>
						<svg className={"back-btn balance"} onClick={handleBackBtnClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
							<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
						</svg>
					</div>
					<CreateNewAutomationProject allIntegrations={allIntegrations} handleBackBtnClick={handleBackBtnClick} />
				</div>
			}

			{
				selectedPage === "edit-auto-project" &&
				<div className={"keyword-project-container is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column"}>
					<div className={"keyword-project-header"}>
						<div className={"keyword-project-header"}>
							<svg className={"back-btn"} onClick={handleBackBtnClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
								<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
							</svg>
							<h3 className={"is-size-3"}>
								Update {selectedTableRow?.projectName}
							</h3>
							<svg className={"back-btn balance"} onClick={handleBackBtnClick} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
								<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
							</svg>
						</div>
					</div>
					{
						selectedTableRow &&
						<EditAutomationProject projectId={selectedTableRow.projectId} handleBackBtnClick={handleBackBtnClick} allIntegrations={allIntegrations} />
					}
				</div>
			}

			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />

		</>
	);
}


function CreateNewAutomationProject(props: AutomationProjectProps) {
	// ------------------ NON STATE CONSTANTS ------------------
	const daysOfWeek = [
		{ short: 'M', full: 'monday' },
		{ short: 'T', full: 'tuesday' },
		{ short: 'W', full: 'wednesday' },
		{ short: 'T', full: 'thursday' },
		{ short: 'F', full: 'friday' },
		{ short: 'S', full: 'saturday' },
		{ short: 'S', full: 'sunday' }
	];

	const AUTO_PUBLISH_STATES = [
		{ value: 'on', label: 'Publish' },
		{ value: 'draft', label: 'Draft' },
		{ value: 'off', label: 'Off' },
		{ value: 'paused', label: 'Paused' }
	]

	const AUTO_PUBLISH_TIMES = [
		{ value: 'any_time', label: 'Any Time' },
		{ value: 'morning', label: 'Morning (06:00 AM to 12:00 PM)' },
		{ value: 'afternoon', label: 'Afternoon (12:00 PM to 06:00 PM)' },
		{ value: 'evening', label: 'Evening (06:00 PM to 12:00 AM)' },
		{ value: 'night', label: 'Night (12:00 AM to 06:00 AM)' }
	]

	// -------------------------- STATES --------------------------
	const [selectedKeywordProjectId, setSelectedKeywordProjectId] = useState<string>('');
	const [trafficRange, setTrafficRange] = useState<number[]>([20, 4000]);
	const [articlesCount, setArticlesCount] = useState<number>(2);
	const [frequency, setFrequency] = useState<string>('daily');
	const [integration, setIntegration] = useState<string>(props.allIntegrations.length === 0 ? "No Integration" : props.allIntegrations[0]);
	const [publishState, setPublishState] = useState<string>('on');
	const [publishDays, setPublishDays] = useState<string[]>(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
	const [publishTime, setPublishTime] = useState<string>('any_time');
	const [publishOnlyGeneratedArticles, setPublishOnlyGeneratedArticles] = useState(false);
	const [allKeywordProjects, setAllKeywordProjects] = useState<any[]>([]);

	const [minInputWidth, setMinInputWidth] = useState<string>('4rem');
	const [maxInputWidth, setMaxInputWidth] = useState<string>('6rem');
	const [articlesCountInputWidth, setArticlesCountInputWidth] = useState<string>('4rem');

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// ----------------------- QUERIES -----------------------
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getKeywordProjects'],
		queryFn: () => getKeywordProjects(),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});

	// ----------------------- MUTATIONS -----------------------
	const SaveAutomationProjectMutation = useMutation(saveAutomationProjectMutation);

	useEffect(() => {
		document.title = "Create New Automation Project | Abun";
	} , []);

	useEffect(() => {
		if (data) {
			if (data.status === 200) {
				setAllKeywordProjects(data.data.keyword_projects as Array<KeywordsProjectData>);
				adjustInputWidth(trafficRange[0].toString(), 'min-input-measure');
				adjustInputWidth(trafficRange[1].toString(), 'max-input-measure');
				adjustInputWidth(articlesCount.toString(), 'articles-count-measure');
			}
		}
	}, [data, allKeywordProjects, trafficRange, articlesCount]);

	const handleDaysChange = (day: string) => {
		// at least one day should be selected
		if (publishDays.length === 1 && publishDays.includes(day)) {
			return;
		}
		// Handle days change for publishing
		setPublishDays(prev =>
			prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
		);
	};

	function adjustInputWidth(inputValue: string, measureId: string) {
		const measure = document.getElementById(measureId) as HTMLElement;
		measure.textContent = inputValue + ' ';
		if (measureId === 'min-input-measure') {
			setMinInputWidth((measure.offsetWidth + 25).toString() + 'px');
		} else if (measureId === 'max-input-measure') {
			setMaxInputWidth((measure.offsetWidth + 35).toString() + 'px');
		} else {
			setArticlesCountInputWidth((measure.offsetWidth + 35).toString() + 'px');
		}
	}

	const handleSubmit = () => {
		// Handle form submission
		SaveAutomationProjectMutation.mutate({
			selectedKeywordProjectId,
			trafficRange,
			articlesCount,
			frequency,
			integration,
			publishState,
			publishDays,
			publishTime,
			publishOnlyGeneratedArticles
		}, {
			onSuccess: (data) => {
				console.log(data);
				props.handleBackBtnClick();
			},
			onError: (error) => {
				console.log(error);
				if (error.message.includes("No keyword projects")) {
					failAlertRef.current?.show(
						`No keyword projects found. Please create a keyword project first.`
					);
					setTimeout(() => {
						failAlertRef.current?.close();
					}, 5000);
				}
			}
		});
	};

	if (isFetching) {
		return (
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"}/>
					</p>
				</div>
			</div>
		)
	} else if (error) {
		return (
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Oops! Something went wrong :( Please try again later.
					</p>
				</div>
			</div>
		)
	} else {
		return (
			<>
				<h5 className={"is-size-4 is-flex is-align-items-center"} style={{ flexDirection: "column", width: "100%", textAlign: "center", justifyContent: "center !important", gap: "1rem" }}>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center', marginTop: '1rem' }}>
						Generate Articles from Keyword Project: &nbsp;
						<Select defaultValue="Any Keyword Project" size='small' sx={{ width: '15rem' }}
							value={allKeywordProjects.find(project => project.projectId === selectedKeywordProjectId)}
							onChange={(e) => {
								const selectedProject: KeywordsProjectData = allKeywordProjects.find(project => project.projectName === e.target.value) || '';
								setSelectedKeywordProjectId(selectedProject.projectId);
							}}>
							<MenuItem value="Any Keyword Project">Any Keyword Project</MenuItem>
							{
								allKeywordProjects.map((project) => (
									<MenuItem key={project.projectId} value={project.projectName}>{project.projectName}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						with more than &nbsp;
						<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							<TextField
								type="number"
								size='small'
								id='trafficRangeMin'
								value={Math.max(0, trafficRange[0])}
								onChange={(e) => {
									// Ensure that the min value is greater than 0 and less than the max value
									setTrafficRange([Math.min(999999999, Math.max(0, Number(e.target.value))), trafficRange[1]])
									adjustInputWidth(e.target.value, 'min-input-measure')
								}}
								sx={{ width: minInputWidth, minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							/>
							<span className="input-measure" id="min-input-measure"></span>
						</div>
						&nbsp; and less than &nbsp;
						<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							<TextField
								type="number"
								size='small'
								id='trafficRangeMax'
								value={Math.max(1, Math.max(trafficRange[0], trafficRange[1]))}
								onChange={(e) => {
									// Ensure that the max value is greater than the min value and less than 999999999
									setTrafficRange([trafficRange[0], Math.min(999999999, Math.max(0, Math.max(trafficRange[0], Number(e.target.value))))])
									adjustInputWidth(e.target.value, 'max-input-measure')
								}}
								sx={{ width: maxInputWidth, minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							/>
							<span className="input-measure" id="max-input-measure"></span>
						</div>
						&nbsp; traffic volume.
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						Submit &nbsp;
						<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							<TextField
								type="number"
								size='small'
								id='articlesCount'
								value={Math.max(1, articlesCount)}
								onChange={(e) => {
									setArticlesCount(Math.max(1, Number(e.target.value)))
									adjustInputWidth(e.target.value, 'articles-count-measure')
								}}
								margin="normal"
								sx={{ width: articlesCountInputWidth, minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							/>
							<span className="input-measure" id="articles-count-measure"></span>
						</div>&nbsp; articles per &nbsp;
						<Select defaultValue="day" size='small'
							value={frequency}
							onChange={(e) => setFrequency(e.target.value.toString())}>
							<MenuItem value="daily">day</MenuItem>
							<MenuItem value="weekly">week</MenuItem>
							<MenuItem value="monthly">month</MenuItem>
						</Select> &nbsp; on &nbsp;
						<Select size='small' value={props.allIntegrations.length === 0 ? "No Integration" : integration}
							onChange={(e) => setIntegration(e.target.value)} disabled={props.allIntegrations.length === 0}>
							{
								props.allIntegrations.map((integration, index) => (
									<MenuItem key={index} value={integration}>{integration.charAt(0).toUpperCase() + integration.substring(1)}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						{
							publishState === 'on' && <p>Directly Publish the Articles</p>
						}
						{
							publishState === 'draft' && <p>Save the Articles as Drafts</p>
						}
						{
							publishState === 'off' && <p>Automation will be turned off</p>
						}
						{
							publishState === 'paused' && <p>Automation will be in paused state</p>
						} &nbsp; &nbsp;
						<Select size='small' value={AUTO_PUBLISH_STATES.find(state => state.value === publishState)?.value} onChange={(e) => setPublishState(e.target.value.toString().toLowerCase())}>
							{
								AUTO_PUBLISH_STATES.map((state) => (
									// ignore off and paused states for new automation projects
									(state.value === 'off' || state.value === 'paused') ? null : <MenuItem key={state.value} value={state.value}>{state.label}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						Publish articles only on these days: &nbsp; &nbsp;
						<div className={"is-flex is-flex-direction-row is-align-items-center is-justify-content-center"}
							style={{ border: '1px solid #ccc', padding: '0.125rem', borderRadius: '0.25rem' }}>
							{daysOfWeek.map(({ short, full }) => (
								<ToggleButtonGroup key={full} value={publishDays.includes(full)} exclusive onChange={() => handleDaysChange(full)}>
									<ToggleButton color="primary" value={true} sx={{ width: '2.25rem', height: '2rem', padding: '0', borderRadius: '0', border: 'none' }}>
										{short}
									</ToggleButton>
								</ToggleButtonGroup>
							))}
						</div>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						Publish/Save Articles: &nbsp; &nbsp;
						<Select size='small' value={AUTO_PUBLISH_TIMES.find(time => time.value === publishTime)?.value} onChange={(e) => setPublishTime(e.target.value)}>
							{
								AUTO_PUBLISH_TIMES.map((time) => (
									<MenuItem key={time.value} value={time.value}>{time.label}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						<div className={"is-flex is-flex-direction-column is-align-items-center is-justify-content-center"} style={{ gap: "0.5rem", height: "4rem" }}>
							<h5 className={"is-size-5"} >Use only Generated Articles to Auto Post:</h5>
							{
								publishOnlyGeneratedArticles ? <p style={{ fontSize: "0.75rem" }}>
									(Keeping it on will not generate New Articles and will only post the available generated articles on the selected days and time.)
								</p>
									: <p style={{ fontSize: "0.75rem" }}>
										(Keeping it off will automatically generate New Articles, if not available, and post them on the selected days and time.)
									</p>
							}
						</div>
						<div className={"is-flex is-flex-direction-row is-align-items-center is-justify-content-center"} style={{ gap: "0.5rem", height: "4rem", transition: "all 5s ease-in-out !important" }}>
							<Switch
								checked={publishOnlyGeneratedArticles}
								onClick={() => setPublishOnlyGeneratedArticles(!publishOnlyGeneratedArticles)}
								inputProps={{ 'aria-label': 'controlled' }}
							/> {
								publishOnlyGeneratedArticles ? <p>Enabled</p> : <p>Disabled</p>
							}
						</div>
					</FormControl>
				</h5>
				<AbunButton type="primary" clickHandler={handleSubmit} disabled={props.allIntegrations.length === 0}>
					Start Automation
				</AbunButton>
				{
					props.allIntegrations.length === 0 &&
					<p style={{ fontSize: "1rem", marginTop: "0.75rem" }}>
						No integrations are available. Please <Link to="/settings?tab=integration">integrate</Link> a website integration to update Automation Project"
					</p>
				}

				{
					selectedKeywordProjectId &&
					<RealTimeEstimations key={selectedKeywordProjectId}
						keywordsProjectId={selectedKeywordProjectId}
						KeywordTrafficRange={trafficRange}
						articlesCount={articlesCount}
						frequency={frequency}
						publishDays={publishDays}
						publishState={publishState}
						publishTime={publishTime}
						publishOnlyGeneratedArticles={publishOnlyGeneratedArticles}
					/>
				}

				<SuccessAlert ref={successAlertRef} />
				<ErrorAlert ref={failAlertRef} />
			</>
		);
	}
};


function EditAutomationProject(props: AutomationProjectProps) {
	// ------------------ NON STATE CONSTANTS ------------------
	const daysOfWeek = [
		{ short: 'M', full: 'monday' },
		{ short: 'T', full: 'tuesday' },
		{ short: 'W', full: 'wednesday' },
		{ short: 'T', full: 'thursday' },
		{ short: 'F', full: 'friday' },
		{ short: 'S', full: 'saturday' },
		{ short: 'S', full: 'sunday' }
	];

	const AUTO_PUBLISH_STATES = [
		{ value: 'on', label: 'Publish' },
		{ value: 'draft', label: 'Draft' },
		{ value: 'off', label: 'Off' },
		{ value: 'paused', label: 'Paused' }
	]

	const AUTO_PUBLISH_TIMES = [
		{ value: 'any_time', label: 'Any Time' },
		{ value: 'morning', label: 'Morning (06:00 AM to 12:00 PM)' },
		{ value: 'afternoon', label: 'Afternoon (12:00 PM to 06:00 PM)' },
		{ value: 'evening', label: 'Evening (06:00 PM to 12:00 AM)' },
		{ value: 'night', label: 'Night (12:00 AM to 06:00 AM)' }
	]

	// -------------------------- STATES --------------------------
	const [associatedKeywordProjectName, setAssociatedKeywordProjectName] = useState<string>('');
	const [associatedKeywordProjectId, setAssociatedKeywordProjectId] = useState<string>('');
	const [trafficRangeMin, setTrafficRangeMin] = useState<number>(20);
	const [trafficRangeMax, setTrafficRangeMax] = useState<number>(4000);
	const [articlesCount, setArticlesCount] = useState<number>(2);
	const [frequency, setFrequency] = useState<string>('daily');
	const [integration, setIntegration] = useState<string>(props.allIntegrations.length === 0 ? "No Integration" : props.allIntegrations[0]);
	const [publishState, setPublishState] = useState<string>('on');
	const [publishDays, setPublishDays] = useState<string[]>(['monday', 'tuesday', 'wednesday', 'thursday', 'friday']);
	const [publishTime, setPublishTime] = useState<string>('any_time');
	const [publishOnlyGeneratedArticles, setPublishOnlyGeneratedArticles] = useState(false);
	const [minInputWidth, setMinInputWidth] = useState<string>('4rem');
	const [maxInputWidth, setMaxInputWidth] = useState<string>('4rem');
	const [articlesCountInputWidth, setArticlesCountInputWidth] = useState<string>('4rem');

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// ----------------------- QUERIES -----------------------
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getAutomationProjectData'],
		queryFn: () => getAutomationProjectData(props.projectId || ""),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});

	// ----------------------- MUTATIONS -----------------------
	const UpdateAutomationProjectMutation = useMutation(updateAutomationProjectMutation);

	useEffect(() => {
		if (data) {
			if (data.status === 200) {
				if (data.data.automation_project as EditAutomationProjectProps) {
					setAssociatedKeywordProjectName(data.data.automation_project.selectedKeywordProjectName);
					setAssociatedKeywordProjectId(data.data.automation_project.selectedKeywordProjectId);
					setTrafficRangeMin(data.data.automation_project.trafficRangeMin);
					setTrafficRangeMax(data.data.automation_project.trafficRangeMax);
					setArticlesCount(data.data.automation_project.articlesCount);
					setFrequency(data.data.automation_project.frequency);
					setIntegration(data.data.automation_project.integration);
					setPublishState(data.data.automation_project.publishState);
					setPublishDays(data.data.automation_project.publishDays);
					setPublishTime(data.data.automation_project.publishTime);
					setPublishOnlyGeneratedArticles(data.data.automation_project.publishOnlyGeneratedArticles);
					adjustInputWidth(data.data.automation_project.trafficRangeMin.toString(), 'min-input-measure');
					adjustInputWidth(data.data.automation_project.trafficRangeMax.toString(), 'max-input-measure');
					adjustInputWidth(data.data.automation_project.articlesCount.toString(), 'articles-count-measure');
				}
			}
		}
	}, [data]);

	const handleDaysChange = (day: string) => {
		// at least one day should be selected
		if (publishDays.length === 1 && publishDays.includes(day)) {
			return;
		}
		// Handle days change for publishing
		setPublishDays(prev =>
			prev.includes(day) ? prev.filter(d => d !== day) : [...prev, day]
		);
	};

	function adjustInputWidth(inputValue: string, measureId: string) {
		const measure = document.getElementById(measureId) as HTMLElement;
		measure.textContent = inputValue + ' ';
		if (measureId === 'min-input-measure') {
			setMinInputWidth((measure.offsetWidth + 35).toString() + 'px');
		} else if (measureId === 'max-input-measure') {
			setMaxInputWidth((measure.offsetWidth + 35).toString() + 'px');
		} else {
			setArticlesCountInputWidth((measure.offsetWidth + 35).toString() + 'px');
		}
	}

	const handleSubmit = () => {
		// Handle form submission
		UpdateAutomationProjectMutation.mutate({
			automationProjectId: props.projectId || "",
			trafficRangeMin,
			trafficRangeMax,
			articlesCount,
			frequency,
			integration,
			publishState,
			publishDays,
			publishTime,
			publishOnlyGeneratedArticles,
		}, {
			onSuccess: (data) => {
				successAlertRef.current?.show(
					`Automation Project updated successfully!`
				);
				setTimeout(() => {
					successAlertRef.current?.close();
				}, 5000);
			},
			onError: (error) => {
				console.log(error);
				failAlertRef.current?.show(
					`Error updating Automation Project. Please try again. ${error.message}`
				);
				setTimeout(() => {
					failAlertRef.current?.close();
				}, 5000);
			}
		});
	};

	if (isFetching) {
		return (
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"}/>
					</p>
				</div>
			</div>
		)
	} else if (error) {
		return (
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Oops! Something went wrong :( Please try again later.
					</p>
				</div>
			</div>
		)
	} else {
		return (
			<>
				<h5 className={"is-size-4 is-flex is-align-items-center"} style={{ flexDirection: "column", width: "100%", textAlign: "center", justifyContent: "center !important", gap: "1rem" }}>

					<FormControl fullWidth margin="normal" sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', width: '75%', justifyContent: 'center' }}>
						<p style={{ margin: '0' }}>
							Generate Articles from Keyword Project: &nbsp;
						</p>
						<b>
							{associatedKeywordProjectName}
						</b>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						with more than &nbsp;
						<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							<TextField
								type="number"
								size='small'
								id='trafficRangeMin'
								value={Math.max(0, trafficRangeMin)}
								onChange={(e) => {
									// Ensure max <= 999999999 and that the min value is greater than 0 and less than the max value
									setTrafficRangeMin(Math.min(999999999, Math.max(0, Number(e.target.value))))
									adjustInputWidth(e.target.value, 'min-input-measure')
								}}
								sx={{ width: minInputWidth, minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							/>
							<span className="input-measure" id="min-input-measure"></span>
						</div>
						&nbsp; and less than &nbsp;
						<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							<TextField
								type="number"
								size='small'
								id='trafficRangeMax'
								// max value should be always greater than min value
								value={Math.max(1, Math.max(trafficRangeMin, trafficRangeMax))}
								onChange={(e) => {
									// Ensure min <= max and max <= 999999999
									setTrafficRangeMax(Math.min(999999999, Math.max(trafficRangeMin, Number(e.target.value))))
									adjustInputWidth(e.target.value, 'max-input-measure')
								}}
								sx={{ width: maxInputWidth, minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							/>
							<span className="input-measure" id="max-input-measure"></span>
						</div>
						&nbsp; traffic volume.
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						Submit &nbsp;
						<div style={{ display: 'inline-block', whiteSpace: 'nowrap' }}>
							<TextField
								type="number"
								size='small'
								id='articlesCount'
								value={Math.max(0, articlesCount)}
								onChange={(e) => {
									setArticlesCount(Math.max(0, Number(e.target.value)))
									adjustInputWidth(e.target.value, 'articles-count-measure')
								}}
								margin="normal"
								sx={{ width: articlesCountInputWidth, minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							/>
							<span className="input-measure" id="articles-count-measure"></span>
						</div>
						&nbsp; articles per &nbsp;
						<Select defaultValue="day" size='small'
							value={frequency}
							onChange={(e) => setFrequency(e.target.value.toString())}>
							<MenuItem value="daily">day</MenuItem>
							<MenuItem value="weekly">week</MenuItem>
							<MenuItem value="monthly">month</MenuItem>
						</Select> &nbsp; on &nbsp;
						<Select size='small' value={props.allIntegrations.length === 0 ? "No Integration" : integration}
							onChange={(e) => setIntegration(e.target.value)} disabled={props.allIntegrations.length === 0}
							sx={{ minWidth: '4rem', maxWidth: '9rem', padding: '0rem' }}
							>
							{
								props.allIntegrations.map((integration, index) => (
									<MenuItem key={index} value={integration}>{integration.charAt(0).toUpperCase() + integration.substring(1)}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						{
							publishState === 'on' && <p>Directly Publish the Articles</p>
						}
						{
							publishState === 'draft' && <p>Save the Articles as Drafts</p>
						}
						{
							publishState === 'off' && <p>Automation will be turned off</p>
						}
						{
							publishState === 'paused' && <p>Automation will be in paused state</p>
						} &nbsp; &nbsp;
						<Select size='small' value={AUTO_PUBLISH_STATES.find(state => state.value === publishState)?.value} onChange={(e) => setPublishState(e.target.value.toString().toLowerCase())}>
							{
								AUTO_PUBLISH_STATES.map((state) => (
									<MenuItem key={state.value} value={state.value}>{state.label}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						Publish articles only on these days: &nbsp; &nbsp;
						<div className={"is-flex is-flex-direction-row is-align-items-center is-justify-content-center"}
							style={{ border: '1px solid #ccc', padding: '0.125rem', borderRadius: '0.25rem' }}>
							{daysOfWeek.map(({ short, full }) => (
								<ToggleButtonGroup key={full} value={publishDays.includes(full)} exclusive onChange={() => handleDaysChange(full)}>
									<ToggleButton color="primary" value={true} sx={{ width: '2rem', height: '2rem', padding: '0', borderRadius: '0', border: 'none' }}>
										{short}
									</ToggleButton>
								</ToggleButtonGroup>
							))}
						</div>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						Publish/Save Articles: &nbsp; &nbsp;
						<Select size='small' value={AUTO_PUBLISH_TIMES.find(time => time.value === publishTime)?.value} onChange={(e) => setPublishTime(e.target.value)}>
							{
								AUTO_PUBLISH_TIMES.map((time) => (
									<MenuItem key={time.value} value={time.value}>{time.label}</MenuItem>
								))
							}
						</Select>
					</FormControl>
					<FormControl sx={{ display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center !important', textAlign: 'center' }}>
						<div className={"is-flex is-flex-direction-column is-align-items-center is-justify-content-center"} style={{ gap: "0.5rem", height: "4rem" }}>
							<h5 className={"is-size-5"} >Use only Generated Articles to Auto Post:</h5>
							{
								publishOnlyGeneratedArticles ? <p style={{ fontSize: "0.75rem" }}>
									(This will not generate New Articles and will only post the available generated articles on the selected days and time.)
								</p>
									: <p style={{ fontSize: "0.75rem" }}>
										(This will automatically generate New Articles, if not available, and post them on the selected days and time.)
									</p>
							}
						</div>
						<div className={"is-flex is-flex-direction-row is-align-items-center is-justify-content-center"} style={{ gap: "0.5rem", height: "4rem" }}>
							<Switch
								checked={publishOnlyGeneratedArticles}
								onClick={() => setPublishOnlyGeneratedArticles(!publishOnlyGeneratedArticles)}
								inputProps={{ 'aria-label': 'controlled' }}
							/> {
								publishOnlyGeneratedArticles ? <p>Enabled</p> : <p>Disabled</p>
							}
						</div>
					</FormControl>
				</h5>

				<AbunButton type="primary" clickHandler={handleSubmit} disabled={props.allIntegrations.length === 0}>
					Update Automation Project
				</AbunButton>
				{
					props.allIntegrations.length === 0 &&
					<div style={{ textAlign: "center" }}>
						<p style={{ fontSize: "1.25rem", marginTop: "0.75rem" }}>
							No integrations are not available.
						</p>
						<p>
							Please <Link to="/settings?tab=integration">integrate</Link> a website integration to update Automation Project or else the automation will be automatically turned off.
						</p>
					</div>
				}

				{
					associatedKeywordProjectId &&
					<RealTimeEstimations key={associatedKeywordProjectId}
						keywordsProjectId={associatedKeywordProjectId}
						KeywordTrafficRange={[trafficRangeMin, trafficRangeMax]}
						articlesCount={articlesCount}
						frequency={frequency}
						publishDays={publishDays}
						publishState={publishState}
						publishTime={publishTime}
						publishOnlyGeneratedArticles={publishOnlyGeneratedArticles}
					/>
				}

				<SuccessAlert ref={successAlertRef} />
				<ErrorAlert ref={failAlertRef} />
			</>
		);
	}
};


function RealTimeEstimations(props: RealTimeEstimationsProps) {
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// --------------- STATES ---------------
	const [tableData, setTableData] = useState<Array<AutomationArticle>>([]);
	const columnHelper = createColumnHelper<AutomationArticle>();
	const [estimatedArticleCount, setEstimatedArticleCount] = useState<number>(0);
	const [estimatedDays, setEstimatedDays] = useState<number>(30);

	// ----------------------- QUERIES -----------------------
	const { data, isFetching, error, refetch } = useQuery({
		queryKey: ['getGeneratedArticlesForAutomationProjectAPI'],
		queryFn: () => getGeneratedArticlesForAutomationProject(props.keywordsProjectId),
		cacheTime: 0,
		refetchOnWindowFocus: false
	});

	useEffect(() => {
		if (data && data.data && data.data.generated_articles) {
			const today = new Date();
			const filterArticles = (articles: AutomationArticle[]) => {
				// Filters the given array of AutomationArticle objects based on keyword volume and uniqueness.
				let chosenKeywords: string[] = [];
				return articles.filter((article: AutomationArticle) => {
					const withinRange = article.keyword_volume >= props.KeywordTrafficRange[0] && article.keyword_volume <= props.KeywordTrafficRange[1];
					const isUniqueKeyword = !chosenKeywords.includes(article.keyword);
					if (withinRange && isUniqueKeyword) {
						chosenKeywords.push(article.keyword);
						return true;
					}
					return false;
				});
			};
	
			const calculateDays = (count: number, articlesCount: number, periodLength: number) => {
				// Calculates the total number of days required for a given count of articles,
				// based on the number of articles per period and the length of each period.
				const periods = Math.ceil(count / articlesCount);
				const totalDays = periods * periodLength;
				// Estimate total days including non-publishing days
				return totalDays + (periods - 1) * (7 - props.publishDays.length); // Adjust for non-publishing days
			};
	
			const getEstimates = (count: number) => {
				// Calculates the estimated number of days and articles based on the given count and frequency.
				let estimatedDays = 0;
				let estimatedArticles = 0;
	
				switch (props.frequency) {
					case 'daily':
						estimatedDays = calculateDays(count, props.articlesCount, 1);
						estimatedArticles = props.articlesCount * Math.ceil(count / props.articlesCount);
						break;
					case 'weekly':
						estimatedDays = calculateDays(count, props.articlesCount, 7);
						estimatedArticles = props.articlesCount * Math.ceil(count / props.articlesCount);
						break;
					case 'monthly':
						estimatedDays = calculateDays(count, props.articlesCount, 30);
						estimatedArticles = props.articlesCount * Math.ceil(count / props.articlesCount);
						break;
					default:
						break;
				}
	
				return { estimatedDays, estimatedArticles };
			};
	
			if (props.publishOnlyGeneratedArticles) {
				const filteredArticles = filterArticles(data.data.generated_articles);
	
				if (filteredArticles.length < 1 || props.publishState === 'off' || props.publishState === 'paused') {
					setTableData([]);
					setEstimatedArticleCount(0);
					setEstimatedDays(0);
					return;
				}
	
				const { estimatedDays, estimatedArticles } = getEstimates(filteredArticles.length);
				setTableData(filteredArticles);
				setEstimatedDays(Math.abs(estimatedDays)); // estimatedDays must only be a positive integer
				setEstimatedArticleCount(Math.min(estimatedArticles, filteredArticles.length));
			} else {
				const distinctKeywordsWithinRange = data.data.associated_keywords.filter((keyword: KeywordProjectKeyword) => {
					return keyword.volume >= props.KeywordTrafficRange[0] && keyword.volume <= props.KeywordTrafficRange[1];
				});
	
				if (distinctKeywordsWithinRange.length < 1 || props.publishState === 'off' || props.publishState === 'paused') {
					setEstimatedArticleCount(0);
					setEstimatedDays(0);
					return;
				}

				let estimatedDays = 0
				let estimatedArticles = Math.min(distinctKeywordsWithinRange.length, props.articlesCount * Math.ceil(distinctKeywordsWithinRange.length / props.articlesCount));
				const publishingDays = distinctKeywordsWithinRange.length / props.articlesCount;

				switch (props.frequency) {
					case 'daily':
						// Calculate the number of weeks needed
						const weeks = Math.ceil(Math.floor(publishingDays / props.publishDays.length));
						// Calculate remaining days
						const remainingDays = publishingDays % props.publishDays.length;
						// Total calendar days considering non-publishing days
						estimatedDays = Math.ceil(weeks * 7 + remainingDays);
						break;
					case 'weekly':
						// Total calendar days considering non-publishing days
						estimatedDays = Math.ceil(publishingDays * 7);
						break;
					case 'monthly':
						// Total calendar days considering non-publishing days
						estimatedDays = Math.ceil(publishingDays * 30);
						break;
					default:
						break;
				}
				setEstimatedDays(Math.abs(estimatedDays)); // estimatedDays must only be a positive integer
				setEstimatedArticleCount(Math.min(estimatedArticles, distinctKeywordsWithinRange.length));
			}
		}
	}, [data, props]);

	const columnDefs = [
		columnHelper.accessor((row: AutomationArticle) => row.title, {
			id: 'articleTitle',
			header: "Article Title",
			cell: cellProps => {
				return cellProps.row.original.title
			},
			enableGlobalFilter: true,
			enableSorting: false,
		}),
		columnHelper.accessor((row: AutomationArticle) => row.keyword, {
			id: 'keyword',
			header: "Keyword [Traffic]",
			cell: info => info.getValue() + (
				(info.row.original.keyword_volume === null || info.row.original.keyword_volume === 0)
					? "" : ` [${info.row.original.keyword_volume}]`
			),
			enableGlobalFilter: false,
		}),
	];

	if (isFetching) {
		return (
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"}/>
					</p>
				</div>
			</div>
		)
	} else if (error) {
		return (
			<div className={"card-content"}>
				<div className={"content is-flex is-justify-content-center"}>
					<p style={{textAlign: "center", fontSize: "1.3rem"}}>
						Oops! Something went wrong :( Please try again later.
					</p>
				</div>
			</div>
		)
	} else {
		return (
			<>
				{/* Real-time estimation: Calculate how many articles would be published in the next 30 days  based on articles count and frequency */}
				<section className={"card mt-5 time-estimation"}>
					<div className={"card-content"}>
						<div className={"content"}>
							<h2 className={"is-size-5"}>
								Real-time Estimations
							</h2>
							<h5 className={"is-size-5"}>Estimated Articles to be Published in the next {estimatedDays} days: {estimatedArticleCount} Articles</h5>
							<p style={{ textAlign: "left", color: "#969696" }}>
								This estimation is based on your current settings. Adjust the frequency and number of articles to see how it affects the estimated count.
							</p>
						</div>
					</div>
				</section>
				{ props.publishOnlyGeneratedArticles &&
					<AbunTable tableContentName={"Articles"}
						tableName='Articles that will be published'
						tableData={tableData}
						columnDefs={columnDefs}
						pageSizes={pageSizes}
						initialPageSize={pageSizes[1]}
						noDataText={"No articles found matching the criteria"}
						searchboxPlaceholderText={"Search articles..."} />
				}
			</>
		);
	}
}
