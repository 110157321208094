import { ChangeEvent, useState } from 'react';

interface ImageUploadProps {
  label: string;
  name?: string
  additionalClasses?: Array<string>
  onChange: (file: File | null) => void;
}

export default function ImageUpload(props : ImageUploadProps) {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      props.onChange(file);
    }
  };

  return (
    <div className={`file has-name ${props?.additionalClasses ? props.additionalClasses.join(" ") : ""}`}>
      <label className="file-label">
        <input
          className="file-input"
          type="file"
          name={props.name}
          accept=".jpg,.jpeg,.png"
          onChange={handleFileChange}
        />
        <span className="file-cta">
          <span className="file-icon">
            <i className="fas fa-upload"></i>
          </span>
          <span className="file-label">
            {props.label}
          </span>
        </span>
        <span className="file-name">
          {selectedFile ? selectedFile.name : 'No file selected'}
        </span>
      </label>
    </div>
  );
};
