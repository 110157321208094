import {useEffect, useRef, useState } from "react";
import { useMutation } from '@tanstack/react-query';
import GenericButton from "../../components/GenericButton/GenericButton";
import {
	GenerateTitlesFromKeyword,
	getGoogleSuggestions
} from "../../utils/api";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";
import Input from "../../components/Input/Input";

import "./createArticleTitleV2.scss";
import { pageURL } from "../routes";
import { Link, useLoaderData } from "react-router-dom";
import MessageBox from "../../components/MessageBox/MessageBox";

import {
	getCountrySuggestions,
	validateCountryCode
} from "./country-iso-codes";


interface ArticlesPageData {
	user_verified: boolean
	stats_title_count?: number
	stats_keywords_count?: number
	stats_competitors_count?: number
	stats_traffic?: Array<number>
	has_active_website: boolean
	content_plan_generation_status: string
}

export default function CreateArticlesV2() {
	// ----------------------- PAGE DATA -----------------------
	const pageData = useLoaderData() as ArticlesPageData;

	// -------------------------- STATES --------------------------
	const [keywordInput, setKeywordInput] = useState("");
	const [locationInput, setLocationInput] = useState("United States [US]");
	const [titlesCountInput, setTitlesCountInput] = useState(10);
	const [locationSuggestions, setLocationSuggestions] = useState<any[]>([]);
	const [keywordSuggestions, setKeywordSuggestions] = useState<any[]>([]);

	// ----------------------- EFFECTS -----------------------
	const titlesForKeywordMutation = useMutation(GenerateTitlesFromKeyword);

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);


	// -------------------------- FUNCTIONS --------------------------
	function uploadKeywordAndLocation() {
		// format keyword, make sure it is not empty and is all caps
		const keyword = keywordInput ? keywordInput.trim().toLowerCase() : "";
		// get titles count, default to 10
		const titlesCount = parseInt(titlesCountInput.toString()) || 10;

		// format countryCode, make sure it is not empty and is all caps
		const userInput = locationInput.split("[")[1]?.split("]")[0].trim().toUpperCase() || "";
		// validate location input
		const location = validateCountryCode(userInput);
		console.log("location Is Valid:", location);
		if (!location) {
			failAlertRef.current?.show(
				`Please select a valid country code from the suggestions.`
			);
			return;
		} else if (!keyword) {
			failAlertRef.current?.show(
				`Please enter a valid keyword.`
			);
			return;
		}
		// if both title and keyword are valid, proceed with mutation
		// titlesForKeywordMutation.mutate({ keyword, location, count: titlesCount }, {
		// 	onSuccess: () => {
		// 		successAlertRef.current?.show(
		// 			`New Titles have been generated successfully!`
		// 		);
		// 	},
		// 	onError: () => {
		// 		failAlertRef.current?.show(
		// 			`Failed to generate new titles. Please try again later.`
		// 		);
		// 	}
		// });
	}

	async function handleTitleCountInput(value: any) {
		if (value < 1) {
			setTitlesCountInput(1);
		} else {
			setTitlesCountInput(value);
		}
	}

	/**
	 * Handles domain input.
	 * @param value
	 */
	async function handleLocationInput(value: string) {
		setLocationInput(value);
		// show suggestions on domain input
		if (value.length > 0) {
			setLocationSuggestions(getCountrySuggestions(value));
		} else {
			setLocationSuggestions([]);
		}
	}

	/**
	 * Handles suggestion click.
	 * @param suggestion
	 */
	function HandleLocationSuggestionClick(suggestion: any) {
		setLocationInput(`${suggestion.name} [${suggestion.code}]`);
		setLocationSuggestions([]);
	}

	/**
	 * Handles suggestion click.
	 * @param suggestion
	 */
	function HandleKeywordSuggestionClick(suggestion: any) {
		setKeywordInput(suggestion);
		setKeywordSuggestions([]);
	}

	// hiding suggestions, on clicking outside of domain input
	const handleOutsideClick = (e: MouseEvent) => {
		const suggestionsContainers = document.querySelectorAll('.suggestions-container');
		const keywordInputField = document.querySelector('.create-article-form .keyword-input');
		const locationInputField = document.querySelector('.create-article-form .location-input');
		suggestionsContainers.forEach((suggestionsContainer) => {
			if (
				suggestionsContainer && !suggestionsContainer.contains(e.target as Node) &&
				((keywordInputField && e.target !== keywordInputField) || (locationInputField && e.target !== locationInputField))
			) {
				setLocationSuggestions([]);
				setKeywordSuggestions([]);
			} else {
				console.log("clicked inside suggestions container",
					(keywordInputField && e.target !== keywordInputField)
				);
			}
		});
	};

	/**
	 * Handles domain input.
	 * @param value
	 */
	async function handleKeywordInput(value: string) {
		setKeywordInput(value);
		// show suggestions on domain input
		if (value.length > 2) {
			// show suggestions from API
			await getGoogleSuggestions(value).then((response) => {
				const data = response.data;
				setKeywordSuggestions(data.suggestions);
			}).catch((error) => {
				console.log(error);
				setKeywordSuggestions([]);
			});
		} else {
			setKeywordSuggestions([]);
		}
	}

	useEffect(() => {
		document.title = "Create Articles | Abun"
		document.addEventListener('click', handleOutsideClick);

		return () => {
			document.removeEventListener('click', handleOutsideClick);
		};
	}, []);

	return (
		<>
			{/* ----------------------------- NO WEBSITE MESSAGE ----------------------------- */}
			{!pageData.has_active_website && <MessageBox title={"No Active Website"}
				type={"warning"}
				className="no-website-message-box"
				blackBodyText={true}
				style={{ maxWidth: "100%" }}>
				You have not added your website with <b>Abun</b> yet. Add your <Link
					to={pageURL['connectWebsite']}><b>website now.</b></Link>
			</MessageBox>}
			<h2 className={"is-size-3 w-100 is-flex is-align-items-center mb-5"}>
				Generate New Article Titles
			</h2>
			<div className={"upload-keyword-container is-flex w-100 is-align-items-center is-justify-content-space-between"}>
				<form className="create-article-form">
					<div className="form-group">
						<label className={"label"} htmlFor="keyword">
							Keyword:
							<svg className="info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="18" width="18">
								<g>
									<path id="Ellipse 69" fill="#fff" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" strokeWidth="3" />
									<path id="Ellipse 68" fill="#ffffff" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" strokeWidth="3" />
									<path id="Union" fill="#ffffff" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" strokeWidth="3" />
									<path id="Ellipse 18" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" strokeWidth="3" />
									<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" strokeWidth="3" />
									<path id="Ellipse 67" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" strokeWidth="3" />
								</g>
							</svg>
							<span className="tooltip">Enter the keyword or search term you want the article to rank for.</span>
						</label>
						<Input value={keywordInput}
							className="keyword-input"
							type={"text"}
							onChange={handleKeywordInput}
							placeholder={"Enter Target Keyword"} />
						{/* show suggestions on domain input */}
						<div className="suggestions-container keyword">
							{keywordSuggestions.map((suggestion, index) => (
								<div key={index} className={"suggestion-item"} onClick={() => HandleKeywordSuggestionClick(suggestion)}>
									<div className={"suggestion-info"}>
										<span title={suggestion}>{suggestion}</span>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="form-group">
						<label className={"label"} htmlFor="keyword">
							No. of Articles to generate:
							<svg className="info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="18" width="18">
								<g>
									<path id="Ellipse 69" fill="#fff" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" strokeWidth="3" />
									<path id="Ellipse 68" fill="#ffffff" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" strokeWidth="3" />
									<path id="Union" fill="#ffffff" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" strokeWidth="3" />
									<path id="Ellipse 18" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" strokeWidth="3" />
									<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" strokeWidth="3" />
									<path id="Ellipse 67" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" strokeWidth="3" />
								</g>
							</svg>
							<span className="tooltip">Enter the no. of articles you want to generate.</span>
						</label>
						<Input value={titlesCountInput.toString()}
							type={"number"}
							onChange={handleTitleCountInput}
							placeholder={"Enter the no. of articles you want to generate"} />
						{keywordInput.includes("@") && <p className={"has-text-centered mt-2"}>Please enter a valid website domain!</p>}
					</div>
					<div className="form-group">
						<label htmlFor="location">
							Location to rank in:
							<svg className="info-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" height="18" width="18">
								<g>
									<path id="Ellipse 69" fill="#fff" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" strokeWidth="3" />
									<path id="Ellipse 68" fill="#ffffff" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" strokeWidth="3" />
									<path id="Union" fill="#ffffff" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" strokeWidth="3" />
									<path id="Ellipse 18" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24 3c11.598 0 21 9.402 21 21s-9.402 21 -21 21S3 35.598 3 24 12.402 3 24 3Z" strokeWidth="3" />
									<path id="Union_2" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M24.944 20.026c1.138 0.066 1.866 0.95 1.937 2.088C26.944 23.14 27 24.697 27 27v5.028c1.27 0.027 2.217 0.068 2.912 0.11 1.094 0.067 1.972 0.74 2.053 1.834 0.022 0.294 0.035 0.634 0.035 1.028s-0.013 0.734 -0.035 1.029c-0.08 1.092 -0.959 1.766 -2.053 1.833 -1.16 0.07 -3.022 0.138 -5.912 0.138s-4.753 -0.068 -5.912 -0.138c-1.094 -0.067 -1.972 -0.74 -2.053 -1.834A14.009 14.009 0 0 1 16 35c0 -0.394 0.013 -0.734 0.035 -1.029 0.08 -1.092 0.959 -1.766 2.053 -1.833a72.898 72.898 0 0 1 2.912 -0.11V27c0 -0.352 0.001 -0.686 0.004 -1.004a32.141 32.141 0 0 1 -1.985 -0.033c-1.187 -0.066 -1.98 -0.941 -2.01 -2.13a33.341 33.341 0 0 1 0 -1.667c0.03 -1.188 0.823 -2.063 2.01 -2.129 0.407 -0.022 0.896 -0.037 1.481 -0.037H24c0.356 0 0.67 0.01 0.944 0.026Z" strokeWidth="3" />
									<path id="Ellipse 67" stroke="#2859c5" strokeLinecap="round" strokeLinejoin="round" d="M20.5 12.5a3.5 3.5 0 1 0 7 0 3.5 3.5 0 1 0 -7 0" strokeWidth="3" />
								</g>
							</svg>
							<span className="tooltip second">Select the location you want the article to rank in.</span>
						</label>
						<Input value={locationInput}
							type={"text"}
							onChange={handleLocationInput}
							placeholder={"Enter the country code (e.g. US, UK)"} />
						{locationInput.includes("@") && <p className={"has-text-centered mt-2"}>Please enter a valid website domain!</p>}
						{/* show suggestions on domain input */}
						<div className="suggestions-container location">
							{locationSuggestions.map((suggestion, index) => (
								<div key={index} className={"suggestion-item"} onClick={() => HandleLocationSuggestionClick(suggestion)}>
									<div className={"suggestion-info"}>
										<span title={suggestion.name}>{suggestion.name}</span>
									</div>
									<span title={suggestion.code}>{suggestion.code}</span>
								</div>
							))}
						</div>
					</div>
					<GenericButton text={titlesForKeywordMutation.isLoading ? "Uploading. Please Wait..." : "Create Articles"}
						icon={"cloud-upload"}
						type={"success"}
						disable={titlesForKeywordMutation.isLoading}
						additionalClassList={["mt-5"]}
						clickHandler={uploadKeywordAndLocation} />
				</form>
			</div>

			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />
		</>
	)
}
