import React, { MutableRefObject, useState, useEffect, Dispatch, SetStateAction } from "react";
import { saveSettingsMutation } from "../../utils/api";
import { useMutation } from "@tanstack/react-query";

interface ExternalBacklinksProps {
    errorAlertRef: MutableRefObject<any>
    successAlertRef: MutableRefObject<any>
    external_backlinks_preference: "no-follow" | "off" | "follow"
    max_internal_backlinks: number
    max_external_backlinks: number
    updatePageData: () => void
    setUnsavedChanges: Dispatch<SetStateAction<boolean>>
}

export default function BacklinksPreference(props: ExternalBacklinksProps) {
    // --------------------- STATES ---------------------
    const [externalBacklinksPreference, setExternalBacklinksPreference] = useState<string>(props.external_backlinks_preference);
    const [maxInternalBacklinks, setMaxInternalBacklinks] = useState<number>(props.max_internal_backlinks);
    const [maxExternalBacklinks, setMaxExternalBacklinks] = useState<number>(props.max_external_backlinks);

    // ---------------------------- MUTATIONS ----------------------------
    const saveSettings = useMutation(saveSettingsMutation);

    // --------------------- FUNCTIONS ---------------------
    const handleBacklinksPreferenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const BacklinksPreference = event.target.value;
        if (
            BacklinksPreference === 'off' ||
            BacklinksPreference === 'no-follow' ||
            BacklinksPreference === 'follow'
        ) {
            if (BacklinksPreference === 'off') {
                document.querySelector('.max-ext-backlinks')?.classList.add('is-hidden');
            } else {
                document.querySelector('.max-ext-backlinks')?.classList.remove('is-hidden');
                setMaxExternalBacklinks(Math.max(1, Math.min(maxExternalBacklinks, 10)));
            }
            setExternalBacklinksPreference(BacklinksPreference);
        } else {
            //console.log('Error: Invalid Backlinks preference');
        }
    };

    const saveBacklinkSettings = async () => {
        saveSettings.mutate({
            settingsToSave: [
                { settingName: 'external_backlinks_preference', settingValue: externalBacklinksPreference },
                { settingName: 'max_internal_backlinks', settingValue: maxInternalBacklinks },
                { settingName: 'max_external_backlinks', settingValue: maxExternalBacklinks }
            ]
        }, {
            onSuccess: () => {
                props.updatePageData();
                props.setUnsavedChanges(false);
                props.successAlertRef.current.show("Settings updated successfully!");
                setTimeout(() => {
                    try {
                        if (props.successAlertRef.current) {
                            props.successAlertRef.current.close();
                        }
                    } catch (e) {}
                }, 3000);
            },
            onError: () => {
                props.errorAlertRef.current?.show("Oops! Something went wrong :( Please try again later or contact us for further support.");
            }
        });
    }

    // on tab change, check if there are unsaved changes
    useEffect(() => {
        if (props.external_backlinks_preference !== externalBacklinksPreference || props.max_internal_backlinks !== maxInternalBacklinks || props.max_external_backlinks !== maxExternalBacklinks) {
            props.setUnsavedChanges(true);
        } else {
            props.setUnsavedChanges(false);
        }
    } , [externalBacklinksPreference, maxInternalBacklinks, maxExternalBacklinks, props]);

    // =====================================================
    // --------------------- MAIN CODE ---------------------
    // =====================================================
    return (
        <div className="settings-sections">
            <div className="card settings-section backlinks-settings">
                <div className="card-content pb-1">
                    <h5 className="settings-section-title">Internal Backlinks Preference for Articles:</h5>
                    {/* set total internal backlinks max 10 */}
                    <div className="mt-5">
                        <div className="field is-horizontal">
                            <label className="label">Maximum number of internal backlinks per article:</label>
                            <input
                                type="number"
                                className="input max-backlinks-input ml-4 mr-3 is-small"
                                min="1"
                                max="10"
                                value={maxInternalBacklinks}
                                onChange={(event) => {
                                    const newValue = Math.max(1, Math.min(Number(event.target.value), 10));
                                    setMaxInternalBacklinks(newValue);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.preventDefault();
                                        setMaxInternalBacklinks(1);
                                    }
                                }}
                                onKeyPress={(event) => {
                                    if (event.key !== 'Backspace') {
                                        // do not allow any inputs other than numbers
                                        if (isNaN(Number(event.key))) {
                                            event.preventDefault();
                                        } else if (Number(event.key) === 0) {
                                            event.preventDefault();
                                            setMaxInternalBacklinks(10);
                                        } else {
                                            event.preventDefault();
                                            const newValue = Math.max(1, Math.min(Number(event.key), 10));
                                            setMaxInternalBacklinks(Number(newValue));
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="card-content">
                    <h5 className="settings-section-title">External Backlinks Preference for Articles:</h5>
                    <div className="control mt-5">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="preference"
                                value="off"
                                onChange={handleBacklinksPreferenceChange}
                                checked={externalBacklinksPreference === 'off'}
                            />
                            <span className="settings-radio-item-title">Do not add external links</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="preference"
                                value="follow"
                                onChange={handleBacklinksPreferenceChange}
                                checked={externalBacklinksPreference === 'follow'}
                            />
                            <span className="settings-radio-item-title">Add external links (follow)</span>
                        </label>
                    </div>
                    <div className="control mt-3">
                        <label className="radio">
                            <input
                                type="radio"
                                className="mr-4"
                                name="preference"
                                value="no-follow"
                                onChange={handleBacklinksPreferenceChange}
                                checked={externalBacklinksPreference === 'no-follow'}
                            />
                            <span className="settings-radio-item-title">Add external links (no-follow)</span>
                        </label>
                    </div>
                    <div className={`mt-5 max-ext-backlinks ${externalBacklinksPreference === 'off' ? 'is-hidden' : ''}`}>
                        <div className="field is-horizontal">
                            <label className="label">Maximum number of external backlinks per article:</label>
                            <input
                                type="number"
                                className="input max-backlinks-input ml-4 mr-3 is-small"
                                min="1"
                                max="10"
                                value={maxExternalBacklinks}
                                onChange={(event) => {
                                    const newValue = Math.max(1, Math.min(Number(event.target.value), 10));
                                    setMaxExternalBacklinks(newValue);
                                }}
                                onKeyDown={(event) => {
                                    if (event.key === 'Backspace') {
                                        event.preventDefault();
                                        setMaxExternalBacklinks(1);
                                    }
                                }}
                                onKeyPress={(event) => {
                                    if (event.key !== 'Backspace') {
                                        // do not allow any inputs other than numbers
                                        if (isNaN(Number(event.key))) {
                                            event.preventDefault();
                                        } else if (Number(event.key) === 0) {
                                            event.preventDefault();
                                            setMaxExternalBacklinks(10);
                                        } else {
                                            event.preventDefault();
                                            const newValue = Math.max(1, Math.min(Number(event.key), 10));
                                            setMaxExternalBacklinks(Number(newValue));
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* -=-=-=-=-=-=-=-=-=-=-= Save All Settings Button -=-=-=-=-=-=-=-=-=-=-= */}
            <div className="save-changes-section mt-4">
                <button
                    className={`button is-primary ${saveSettings.isLoading ? 'is-loading' : ''}`}
                    onClick={saveBacklinkSettings}
                >
                    Save Changes
                </button>
            </div>
        </div>
    );
}