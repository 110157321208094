
interface CountryType {
    location_code: number;
    location_name: string;
    country_iso_code: string;
}

const countries: readonly CountryType[] = [
    {
        "location_code": 2004,
        "location_name": "Afghanistan",
        "country_iso_code": "AF"
    },
    {
        "location_code": 2008,
        "location_name": "Albania",
        "country_iso_code": "AL"
    },
    {
        "location_code": 2010,
        "location_name": "Antarctica",
        "country_iso_code": "AQ"
    },
    {
        "location_code": 2012,
        "location_name": "Algeria",
        "country_iso_code": "DZ"
    },
    {
        "location_code": 2016,
        "location_name": "American Samoa",
        "country_iso_code": "AS"
    },
    {
        "location_code": 2020,
        "location_name": "Andorra",
        "country_iso_code": "AD"
    },
    {
        "location_code": 2024,
        "location_name": "Angola",
        "country_iso_code": "AO"
    },
    {
        "location_code": 2028,
        "location_name": "Antigua and Barbuda",
        "country_iso_code": "AG"
    },
    {
        "location_code": 2031,
        "location_name": "Azerbaijan",
        "country_iso_code": "AZ"
    },
    {
        "location_code": 2032,
        "location_name": "Argentina",
        "country_iso_code": "AR"
    },
    {
        "location_code": 2036,
        "location_name": "Australia",
        "country_iso_code": "AU"
    },
    {
        "location_code": 2040,
        "location_name": "Austria",
        "country_iso_code": "AT"
    },
    {
        "location_code": 2044,
        "location_name": "The Bahamas",
        "country_iso_code": "BS"
    },
    {
        "location_code": 2048,
        "location_name": "Bahrain",
        "country_iso_code": "BH"
    },
    {
        "location_code": 2050,
        "location_name": "Bangladesh",
        "country_iso_code": "BD"
    },
    {
        "location_code": 2051,
        "location_name": "Armenia",
        "country_iso_code": "AM"
    },
    {
        "location_code": 2052,
        "location_name": "Barbados",
        "country_iso_code": "BB"
    },
    {
        "location_code": 2056,
        "location_name": "Belgium",
        "country_iso_code": "BE"
    },
    {
        "location_code": 2064,
        "location_name": "Bhutan",
        "country_iso_code": "BT"
    },
    {
        "location_code": 2068,
        "location_name": "Bolivia",
        "country_iso_code": "BO"
    },
    {
        "location_code": 2070,
        "location_name": "Bosnia and Herzegovina",
        "country_iso_code": "BA"
    },
    {
        "location_code": 2072,
        "location_name": "Botswana",
        "country_iso_code": "BW"
    },
    {
        "location_code": 2076,
        "location_name": "Brazil",
        "country_iso_code": "BR"
    },
    {
        "location_code": 2084,
        "location_name": "Belize",
        "country_iso_code": "BZ"
    },
    {
        "location_code": 2090,
        "location_name": "Solomon Islands",
        "country_iso_code": "SB"
    },
    {
        "location_code": 2096,
        "location_name": "Brunei",
        "country_iso_code": "BN"
    },
    {
        "location_code": 2100,
        "location_name": "Bulgaria",
        "country_iso_code": "BG"
    },
    {
        "location_code": 2104,
        "location_name": "Myanmar (Burma)",
        "country_iso_code": "MM"
    },
    {
        "location_code": 2108,
        "location_name": "Burundi",
        "country_iso_code": "BI"
    },
    {
        "location_code": 2116,
        "location_name": "Cambodia",
        "country_iso_code": "KH"
    },
    {
        "location_code": 2120,
        "location_name": "Cameroon",
        "country_iso_code": "CM"
    },
    {
        "location_code": 2124,
        "location_name": "Canada",
        "country_iso_code": "CA"
    },
    {
        "location_code": 2132,
        "location_name": "Cabo Verde",
        "country_iso_code": "CV"
    },
    {
        "location_code": 2140,
        "location_name": "Central African Republic",
        "country_iso_code": "CF"
    },
    {
        "location_code": 2144,
        "location_name": "Sri Lanka",
        "country_iso_code": "LK"
    },
    {
        "location_code": 2148,
        "location_name": "Chad",
        "country_iso_code": "TD"
    },
    {
        "location_code": 2152,
        "location_name": "Chile",
        "country_iso_code": "CL"
    },
    {
        "location_code": 2156,
        "location_name": "China",
        "country_iso_code": "CN"
    },
    {
        "location_code": 2162,
        "location_name": "Christmas Island",
        "country_iso_code": "CX"
    },
    {
        "location_code": 2166,
        "location_name": "Cocos (Keeling) Islands",
        "country_iso_code": "CC"
    },
    {
        "location_code": 2170,
        "location_name": "Colombia",
        "country_iso_code": "CO"
    },
    {
        "location_code": 2174,
        "location_name": "Comoros",
        "country_iso_code": "KM"
    },
    {
        "location_code": 2178,
        "location_name": "Republic of the Congo",
        "country_iso_code": "CG"
    },
    {
        "location_code": 2180,
        "location_name": "Democratic Republic of the Congo",
        "country_iso_code": "CD"
    },
    {
        "location_code": 2184,
        "location_name": "Cook Islands",
        "country_iso_code": "CK"
    },
    {
        "location_code": 2188,
        "location_name": "Costa Rica",
        "country_iso_code": "CR"
    },
    {
        "location_code": 2191,
        "location_name": "Croatia",
        "country_iso_code": "HR"
    },
    {
        "location_code": 2196,
        "location_name": "Cyprus",
        "country_iso_code": "CY"
    },
    {
        "location_code": 2203,
        "location_name": "Czechia",
        "country_iso_code": "CZ"
    },
    {
        "location_code": 2204,
        "location_name": "Benin",
        "country_iso_code": "BJ"
    },
    {
        "location_code": 2208,
        "location_name": "Denmark",
        "country_iso_code": "DK"
    },
    {
        "location_code": 2212,
        "location_name": "Dominica",
        "country_iso_code": "DM"
    },
    {
        "location_code": 2214,
        "location_name": "Dominican Republic",
        "country_iso_code": "DO"
    },
    {
        "location_code": 2218,
        "location_name": "Ecuador",
        "country_iso_code": "EC"
    },
    {
        "location_code": 2222,
        "location_name": "El Salvador",
        "country_iso_code": "SV"
    },
    {
        "location_code": 2226,
        "location_name": "Equatorial Guinea",
        "country_iso_code": "GQ"
    },
    {
        "location_code": 2231,
        "location_name": "Ethiopia",
        "country_iso_code": "ET"
    },
    {
        "location_code": 2232,
        "location_name": "Eritrea",
        "country_iso_code": "ER"
    },
    {
        "location_code": 2233,
        "location_name": "Estonia",
        "country_iso_code": "EE"
    },
    {
        "location_code": 2239,
        "location_name": "South Georgia and the South Sandwich Islands",
        "country_iso_code": "GS"
    },
    {
        "location_code": 2242,
        "location_name": "Fiji",
        "country_iso_code": "FJ"
    },
    {
        "location_code": 2246,
        "location_name": "Finland",
        "country_iso_code": "FI"
    },
    {
        "location_code": 2250,
        "location_name": "France",
        "country_iso_code": "FR"
    },
    {
        "location_code": 2258,
        "location_name": "French Polynesia",
        "country_iso_code": "PF"
    },
    {
        "location_code": 2260,
        "location_name": "French Southern and Antarctic Lands",
        "country_iso_code": "TF"
    },
    {
        "location_code": 2262,
        "location_name": "Djibouti",
        "country_iso_code": "DJ"
    },
    {
        "location_code": 2266,
        "location_name": "Gabon",
        "country_iso_code": "GA"
    },
    {
        "location_code": 2268,
        "location_name": "Georgia",
        "country_iso_code": "GE"
    },
    {
        "location_code": 2270,
        "location_name": "The Gambia",
        "country_iso_code": "GM"
    },
    {
        "location_code": 2276,
        "location_name": "Germany",
        "country_iso_code": "DE"
    },
    {
        "location_code": 2288,
        "location_name": "Ghana",
        "country_iso_code": "GH"
    },
    {
        "location_code": 2296,
        "location_name": "Kiribati",
        "country_iso_code": "KI"
    },
    {
        "location_code": 2300,
        "location_name": "Greece",
        "country_iso_code": "GR"
    },
    {
        "location_code": 2308,
        "location_name": "Grenada",
        "country_iso_code": "GD"
    },
    {
        "location_code": 2316,
        "location_name": "Guam",
        "country_iso_code": "GU"
    },
    {
        "location_code": 2320,
        "location_name": "Guatemala",
        "country_iso_code": "GT"
    },
    {
        "location_code": 2324,
        "location_name": "Guinea",
        "country_iso_code": "GN"
    },
    {
        "location_code": 2328,
        "location_name": "Guyana",
        "country_iso_code": "GY"
    },
    {
        "location_code": 2332,
        "location_name": "Haiti",
        "country_iso_code": "HT"
    },
    {
        "location_code": 2334,
        "location_name": "Heard Island and McDonald Islands",
        "country_iso_code": "HM"
    },
    {
        "location_code": 2336,
        "location_name": "Vatican City",
        "country_iso_code": "VA"
    },
    {
        "location_code": 2340,
        "location_name": "Honduras",
        "country_iso_code": "HN"
    },
    {
        "location_code": 2348,
        "location_name": "Hungary",
        "country_iso_code": "HU"
    },
    {
        "location_code": 2352,
        "location_name": "Iceland",
        "country_iso_code": "IS"
    },
    {
        "location_code": 2356,
        "location_name": "India",
        "country_iso_code": "IN"
    },
    {
        "location_code": 2360,
        "location_name": "Indonesia",
        "country_iso_code": "ID"
    },
    {
        "location_code": 2368,
        "location_name": "Iraq",
        "country_iso_code": "IQ"
    },
    {
        "location_code": 2372,
        "location_name": "Ireland",
        "country_iso_code": "IE"
    },
    {
        "location_code": 2376,
        "location_name": "Israel",
        "country_iso_code": "IL"
    },
    {
        "location_code": 2380,
        "location_name": "Italy",
        "country_iso_code": "IT"
    },
    {
        "location_code": 2384,
        "location_name": "Cote d'Ivoire",
        "country_iso_code": "CI"
    },
    {
        "location_code": 2388,
        "location_name": "Jamaica",
        "country_iso_code": "JM"
    },
    {
        "location_code": 2392,
        "location_name": "Japan",
        "country_iso_code": "JP"
    },
    {
        "location_code": 2398,
        "location_name": "Kazakhstan",
        "country_iso_code": "KZ"
    },
    {
        "location_code": 2400,
        "location_name": "Jordan",
        "country_iso_code": "JO"
    },
    {
        "location_code": 2404,
        "location_name": "Kenya",
        "country_iso_code": "KE"
    },
    {
        "location_code": 2410,
        "location_name": "South Korea",
        "country_iso_code": "KR"
    },
    {
        "location_code": 2414,
        "location_name": "Kuwait",
        "country_iso_code": "KW"
    },
    {
        "location_code": 2417,
        "location_name": "Kyrgyzstan",
        "country_iso_code": "KG"
    },
    {
        "location_code": 2418,
        "location_name": "Laos",
        "country_iso_code": "LA"
    },
    {
        "location_code": 2422,
        "location_name": "Lebanon",
        "country_iso_code": "LB"
    },
    {
        "location_code": 2426,
        "location_name": "Lesotho",
        "country_iso_code": "LS"
    },
    {
        "location_code": 2428,
        "location_name": "Latvia",
        "country_iso_code": "LV"
    },
    {
        "location_code": 2430,
        "location_name": "Liberia",
        "country_iso_code": "LR"
    },
    {
        "location_code": 2434,
        "location_name": "Libya",
        "country_iso_code": "LY"
    },
    {
        "location_code": 2438,
        "location_name": "Liechtenstein",
        "country_iso_code": "LI"
    },
    {
        "location_code": 2440,
        "location_name": "Lithuania",
        "country_iso_code": "LT"
    },
    {
        "location_code": 2442,
        "location_name": "Luxembourg",
        "country_iso_code": "LU"
    },
    {
        "location_code": 2450,
        "location_name": "Madagascar",
        "country_iso_code": "MG"
    },
    {
        "location_code": 2454,
        "location_name": "Malawi",
        "country_iso_code": "MW"
    },
    {
        "location_code": 2458,
        "location_name": "Malaysia",
        "country_iso_code": "MY"
    },
    {
        "location_code": 2462,
        "location_name": "Maldives",
        "country_iso_code": "MV"
    },
    {
        "location_code": 2466,
        "location_name": "Mali",
        "country_iso_code": "ML"
    },
    {
        "location_code": 2470,
        "location_name": "Malta",
        "country_iso_code": "MT"
    },
    {
        "location_code": 2478,
        "location_name": "Mauritania",
        "country_iso_code": "MR"
    },
    {
        "location_code": 2480,
        "location_name": "Mauritius",
        "country_iso_code": "MU"
    },
    {
        "location_code": 2484,
        "location_name": "Mexico",
        "country_iso_code": "MX"
    },
    {
        "location_code": 2492,
        "location_name": "Monaco",
        "country_iso_code": "MC"
    },
    {
        "location_code": 2496,
        "location_name": "Mongolia",
        "country_iso_code": "MN"
    },
    {
        "location_code": 2498,
        "location_name": "Moldova",
        "country_iso_code": "MD"
    },
    {
        "location_code": 2499,
        "location_name": "Montenegro",
        "country_iso_code": "ME"
    },
    {
        "location_code": 2504,
        "location_name": "Morocco",
        "country_iso_code": "MA"
    },
    {
        "location_code": 2508,
        "location_name": "Mozambique",
        "country_iso_code": "MZ"
    },
    {
        "location_code": 2512,
        "location_name": "Oman",
        "country_iso_code": "OM"
    },
    {
        "location_code": 2516,
        "location_name": "Namibia",
        "country_iso_code": "NA"
    },
    {
        "location_code": 2520,
        "location_name": "Nauru",
        "country_iso_code": "NR"
    },
    {
        "location_code": 2524,
        "location_name": "Nepal",
        "country_iso_code": "NP"
    },
    {
        "location_code": 2528,
        "location_name": "Netherlands",
        "country_iso_code": "NL"
    },
    {
        "location_code": 2531,
        "location_name": "Curacao",
        "country_iso_code": "CW"
    },
    {
        "location_code": 2534,
        "location_name": "Sint Maarten",
        "country_iso_code": "SX"
    },
    {
        "location_code": 2535,
        "location_name": "Caribbean Netherlands",
        "country_iso_code": "BQ"
    },
    {
        "location_code": 2540,
        "location_name": "New Caledonia",
        "country_iso_code": "NC"
    },
    {
        "location_code": 2548,
        "location_name": "Vanuatu",
        "country_iso_code": "VU"
    },
    {
        "location_code": 2554,
        "location_name": "New Zealand",
        "country_iso_code": "NZ"
    },
    {
        "location_code": 2558,
        "location_name": "Nicaragua",
        "country_iso_code": "NI"
    },
    {
        "location_code": 2562,
        "location_name": "Niger",
        "country_iso_code": "NE"
    },
    {
        "location_code": 2566,
        "location_name": "Nigeria",
        "country_iso_code": "NG"
    },
    {
        "location_code": 2570,
        "location_name": "Niue",
        "country_iso_code": "NU"
    },
    {
        "location_code": 2574,
        "location_name": "Norfolk Island",
        "country_iso_code": "NF"
    },
    {
        "location_code": 2578,
        "location_name": "Norway",
        "country_iso_code": "NO"
    },
    {
        "location_code": 2580,
        "location_name": "Northern Mariana Islands",
        "country_iso_code": "MP"
    },
    {
        "location_code": 2581,
        "location_name": "United States Minor Outlying Islands",
        "country_iso_code": "UM"
    },
    {
        "location_code": 2583,
        "location_name": "Micronesia",
        "country_iso_code": "FM"
    },
    {
        "location_code": 2584,
        "location_name": "Marshall Islands",
        "country_iso_code": "MH"
    },
    {
        "location_code": 2585,
        "location_name": "Palau",
        "country_iso_code": "PW"
    },
    {
        "location_code": 2586,
        "location_name": "Pakistan",
        "country_iso_code": "PK"
    },
    {
        "location_code": 2591,
        "location_name": "Panama",
        "country_iso_code": "PA"
    },
    {
        "location_code": 2598,
        "location_name": "Papua New Guinea",
        "country_iso_code": "PG"
    },
    {
        "location_code": 2600,
        "location_name": "Paraguay",
        "country_iso_code": "PY"
    },
    {
        "location_code": 2604,
        "location_name": "Peru",
        "country_iso_code": "PE"
    },
    {
        "location_code": 2608,
        "location_name": "Philippines",
        "country_iso_code": "PH"
    },
    {
        "location_code": 2612,
        "location_name": "Pitcairn Islands",
        "country_iso_code": "PN"
    },
    {
        "location_code": 2616,
        "location_name": "Poland",
        "country_iso_code": "PL"
    },
    {
        "location_code": 2620,
        "location_name": "Portugal",
        "country_iso_code": "PT"
    },
    {
        "location_code": 2624,
        "location_name": "Guinea-Bissau",
        "country_iso_code": "GW"
    },
    {
        "location_code": 2626,
        "location_name": "Timor-Leste",
        "country_iso_code": "TL"
    },
    {
        "location_code": 2634,
        "location_name": "Qatar",
        "country_iso_code": "QA"
    },
    {
        "location_code": 2642,
        "location_name": "Romania",
        "country_iso_code": "RO"
    },
    {
        "location_code": 2646,
        "location_name": "Rwanda",
        "country_iso_code": "RW"
    },
    {
        "location_code": 2652,
        "location_name": "Saint Barthelemy",
        "country_iso_code": "BL"
    },
    {
        "location_code": 2654,
        "location_name": "Saint Helena, Ascension and Tristan da Cunha",
        "country_iso_code": "SH"
    },
    {
        "location_code": 2659,
        "location_name": "Saint Kitts and Nevis",
        "country_iso_code": "KN"
    },
    {
        "location_code": 2662,
        "location_name": "Saint Lucia",
        "country_iso_code": "LC"
    },
    {
        "location_code": 2663,
        "location_name": "Saint Martin",
        "country_iso_code": "MF"
    },
    {
        "location_code": 2666,
        "location_name": "Saint Pierre and Miquelon",
        "country_iso_code": "PM"
    },
    {
        "location_code": 2670,
        "location_name": "Saint Vincent and the Grenadines",
        "country_iso_code": "VC"
    },
    {
        "location_code": 2674,
        "location_name": "San Marino",
        "country_iso_code": "SM"
    },
    {
        "location_code": 2678,
        "location_name": "Sao Tome and Principe",
        "country_iso_code": "ST"
    },
    {
        "location_code": 2682,
        "location_name": "Saudi Arabia",
        "country_iso_code": "SA"
    },
    {
        "location_code": 2686,
        "location_name": "Senegal",
        "country_iso_code": "SN"
    },
    {
        "location_code": 2688,
        "location_name": "Serbia",
        "country_iso_code": "RS"
    },
    {
        "location_code": 2690,
        "location_name": "Seychelles",
        "country_iso_code": "SC"
    },
    {
        "location_code": 2694,
        "location_name": "Sierra Leone",
        "country_iso_code": "SL"
    },
    {
        "location_code": 2702,
        "location_name": "Singapore",
        "country_iso_code": "SG"
    },
    {
        "location_code": 2703,
        "location_name": "Slovakia",
        "country_iso_code": "SK"
    },
    {
        "location_code": 2704,
        "location_name": "Vietnam",
        "country_iso_code": "VN"
    },
    {
        "location_code": 2705,
        "location_name": "Slovenia",
        "country_iso_code": "SI"
    },
    {
        "location_code": 2706,
        "location_name": "Somalia",
        "country_iso_code": "SO"
    },
    {
        "location_code": 2710,
        "location_name": "South Africa",
        "country_iso_code": "ZA"
    },
    {
        "location_code": 2716,
        "location_name": "Zimbabwe",
        "country_iso_code": "ZW"
    },
    {
        "location_code": 2724,
        "location_name": "Spain",
        "country_iso_code": "ES"
    },
    {
        "location_code": 2728,
        "location_name": "South Sudan",
        "country_iso_code": "SS"
    },
    {
        "location_code": 2736,
        "location_name": "Sudan",
        "country_iso_code": "SD"
    },
    {
        "location_code": 2740,
        "location_name": "Suriname",
        "country_iso_code": "SR"
    },
    {
        "location_code": 2748,
        "location_name": "Eswatini",
        "country_iso_code": "SZ"
    },
    {
        "location_code": 2752,
        "location_name": "Sweden",
        "country_iso_code": "SE"
    },
    {
        "location_code": 2756,
        "location_name": "Switzerland",
        "country_iso_code": "CH"
    },
    {
        "location_code": 2762,
        "location_name": "Tajikistan",
        "country_iso_code": "TJ"
    },
    {
        "location_code": 2764,
        "location_name": "Thailand",
        "country_iso_code": "TH"
    },
    {
        "location_code": 2768,
        "location_name": "Togo",
        "country_iso_code": "TG"
    },
    {
        "location_code": 2772,
        "location_name": "Tokelau",
        "country_iso_code": "TK"
    },
    {
        "location_code": 2776,
        "location_name": "Tonga",
        "country_iso_code": "TO"
    },
    {
        "location_code": 2780,
        "location_name": "Trinidad and Tobago",
        "country_iso_code": "TT"
    },
    {
        "location_code": 2784,
        "location_name": "United Arab Emirates",
        "country_iso_code": "AE"
    },
    {
        "location_code": 2788,
        "location_name": "Tunisia",
        "country_iso_code": "TN"
    },
    {
        "location_code": 2792,
        "location_name": "Turkiye",
        "country_iso_code": "TR"
    },
    {
        "location_code": 2795,
        "location_name": "Turkmenistan",
        "country_iso_code": "TM"
    },
    {
        "location_code": 2798,
        "location_name": "Tuvalu",
        "country_iso_code": "TV"
    },
    {
        "location_code": 2800,
        "location_name": "Uganda",
        "country_iso_code": "UG"
    },
    {
        "location_code": 2804,
        "location_name": "Ukraine",
        "country_iso_code": "UA"
    },
    {
        "location_code": 2807,
        "location_name": "North Macedonia",
        "country_iso_code": "MK"
    },
    {
        "location_code": 2818,
        "location_name": "Egypt",
        "country_iso_code": "EG"
    },
    {
        "location_code": 2826,
        "location_name": "United Kingdom",
        "country_iso_code": "GB"
    },
    {
        "location_code": 2831,
        "location_name": "Guernsey",
        "country_iso_code": "GG"
    },
    {
        "location_code": 2832,
        "location_name": "Jersey",
        "country_iso_code": "JE"
    },
    {
        "location_code": 2833,
        "location_name": "Isle of Man",
        "country_iso_code": "IM"
    },
    {
        "location_code": 2834,
        "location_name": "Tanzania",
        "country_iso_code": "TZ"
    },
    {
        "location_code": 2840,
        "location_name": "United States",
        "country_iso_code": "US"
    },
    {
        "location_code": 2854,
        "location_name": "Burkina Faso",
        "country_iso_code": "BF"
    },
    {
        "location_code": 2858,
        "location_name": "Uruguay",
        "country_iso_code": "UY"
    },
    {
        "location_code": 2860,
        "location_name": "Uzbekistan",
        "country_iso_code": "UZ"
    },
    {
        "location_code": 2862,
        "location_name": "Venezuela",
        "country_iso_code": "VE"
    },
    {
        "location_code": 2876,
        "location_name": "Wallis and Futuna",
        "country_iso_code": "WF"
    },
    {
        "location_code": 2882,
        "location_name": "Samoa",
        "country_iso_code": "WS"
    },
    {
        "location_code": 2887,
        "location_name": "Yemen",
        "country_iso_code": "YE"
    },
    {
        "location_code": 2894,
        "location_name": "Zambia",
        "country_iso_code": "ZM"
    }
]

export default countries;