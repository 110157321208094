import { MutableRefObject, useEffect, useState, useRef } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useLoaderData, useSearchParams, useRevalidator } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import { Tooltip } from "react-tooltip";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import LinkButton from "../../components/LinkButton/LinkButton";
import GenericButton from "../../components/GenericButton/GenericButton";
import AbunTable from "../../components/AbunTable/AbunTable";
import {
	generateV2ArticleMutation,
	postArticleMutation,
	archiveBulkArticleMutation,
	createCustomTitleForKeywordMutation,
	GenerateTitlesFromKeyword
} from "../../utils/api";
import AbunModal from "../../components/AbunModal/AbunModal";
import loadingIcon from "../../assets/images/loadingIcon.webp"
import wordpressIconSuccess from "../../assets/images/wordpress-logo.png";
import webflowIconSuccess from "../../assets/images/webflow-logo.png";
import wordpressIconPrimary from "../../assets/images/wordpress-logo-primary.png";
import webflowIconPrimary from "../../assets/images/webflow-logo-primary.png";
import articleIntegrationIcon from "../../assets/images/article-integration-logo.png"

import "../Articles/Articles.scss";
import { pageURL } from "../routes";
import { useNavigate } from "react-router-dom";
import AbunButton from "../../components/AbunButton/AbunButton";
import SuccessAlert from "../../components/SuccessAlert/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert/ErrorAlert";

interface ServerData {
	titles_data: Array<ArticleTitle>
	all_integrations: any
	google_search_console_integrated: boolean;
	user_verified: boolean;
	keyword: string;
	keywordHash: string;
	keywordTraffic: number;
	difficultyScore: string;
	locationIsoCode: string;
	keyword_project_id: string;
}

interface ArticleTitle {
	articleUID: string
	articleTitle: string
	keyword: string
	keywordHash: string;
	keywordTraffic: number | null
	internalLinks: number | null
	externalLinks: number | null
	images: number | null
	wordCount: number | null
	isProcessing: boolean
	isGenerated: boolean
	isPosted: boolean
	isFailed: boolean
	isArchived: boolean
	postLink: string
	isUserAdded: boolean
	postedTo: string
}

interface InitialSortingState {
	id: string;
	desc: boolean;
}

export default function ShowTitlesForKeyword() {
	// ----------------------- REFS -----------------------
	const navigate = useNavigate();

	// ----------------------- NON STATE CONSTANTS -----------------------
	const pageSizes = [5, 10, 15, 30, 50, 100, 500];

	// ----------------------- STATES -----------------------
	const [tableData, setTableData] = useState<Array<ArticleTitle>>([]);
	const [requestModalActive, setRequestModalActive] = useState(false);
	const [integrationModalActive, setIntegrationModalActive] = useState(false);
	const [modalText, setModalText] = useState("");
	const [allIntegrations, setAllIntegrations] = useState<Array<string>>([]);
	const [selectedIntegration, setSelectedIntegration] = useState("");
	const [showCreateCustomTitleModal, setShowCreateCustomTitleModal] = useState(false);
	const [customTitle, setCustomTitle] = useState("");
	const [initialSortingState, setInitialSortingState] = useState<InitialSortingState[]>([{
		id: "wordCount", desc: true
	}])
	const [userVerified, setUserVerified] = useState(false);
	const [titlesGenerated, setTitlesGenerated] = useState(false);
	const [openDropdown, setOpenDropdown] = useState("");

	// ----------------------- REFS -----------------------
	const successAlertRef = useRef<any>(null);
	const failAlertRef = useRef<any>(null);

	// ----------------------------- LOADER -----------------------------
	const pageData: ServerData = useLoaderData() as ServerData;
	const { revalidate } = useRevalidator();

	// ----------------------- MUTATIONS -----------------------
	const generateArticle = useMutation(generateV2ArticleMutation);
	const postArticle = useMutation(postArticleMutation);
	const archiveSelectedArticles = useMutation(archiveBulkArticleMutation);
	const createCustomTitleForKeyword = useMutation(createCustomTitleForKeywordMutation);
	const titlesForKeywordMutation = useMutation(GenerateTitlesFromKeyword);

	// ----------------------- EFFECTS -----------------------
	useEffect(() => {
		document.title = "Articles | Abun"
	}, []);

	useEffect(() => {
		if (pageData) {
			// {
			// 	"page": "keyword_project_titles",
			// 	"titles_data": [
			// 	],
			// 	"all_integrations": [
			// 		"wordpress"
			// 	],
			// 	"google_search_console_integrated": true,
			// 	"user_verified": true,
			// 	"keyword": "ant design system",
			// 	"keywordHash": "186b15f7369933f9ad95855f0d235736",
			// 	"keywordTraffic": 260,
			// 	"difficultyScore": "0.00",
			// 	"keyword_project_id": "8001f588-f4b6-4e",
			// 	"locationIsoCode": "us"
			// }
			if (pageData['titles_data']) {
				setTitlesGenerated(true);
				// filter out the articles that do not have props.keyword.keyword
				pageData['titles_data'] = pageData['titles_data'].filter((article: ArticleTitle) => {
					return article.keyword === pageData.keyword || article.keywordHash === pageData.keywordHash;
				})

				// sort the data according to the descending order of keyword traffic
				pageData['titles_data'].sort((a: ArticleTitle, b: ArticleTitle) => {
					if (a.keywordTraffic === null) {
						return 1;
					} else if (b.keywordTraffic === null) {
						return -1;
					} else {
						return b.keywordTraffic - a.keywordTraffic;
					}
				});

				// sorting the data so that user added titles are shown first
				pageData['titles_data'].sort((a: ArticleTitle, b: ArticleTitle) => {
					if (a.isUserAdded && !b.isUserAdded) {
						return -1;
					} else if (!a.isUserAdded && b.isUserAdded) {
						return 1;
					} else {
						return 0;
					}
				});
			}
			setAllIntegrations((pageData as any)['all_integrations']);
			setUserVerified((pageData as any)['user_verified']);

			// hide the table column according to the selected tab
			const tab_to_hide = document.querySelector("#article-titles-table .hide-column-child")?.parentElement || document.querySelector("#article-titles-table .hide-column");

			// filter the data according to the selected tab
			while (tab_to_hide && tab_to_hide.classList.contains("hide-column")) {
				tab_to_hide.classList.remove("hide-column");
			}
			setTableData((pageData as any)['titles_data'].filter((article: ArticleTitle) => {
				return !article.isArchived;
			}));
		}
	}, [pageData]);

	// =========================================================
	// ----------------------- MAIN CODE -----------------------
	// =========================================================
	function generateArticleHandler(articleUID: string) {
		setModalText("Processing request. Please wait...");
		setRequestModalActive(true);
		generateArticle.mutate(articleUID, {
			onSuccess: (data) => {
				// only 200 response is handled here
				setRequestModalActive(false);
				let responseData = (data as any)["data"];

				if (responseData["status"] === "sent_for_processing") {
					navigate(`/articles/edit/${articleUID}/`);
				} else if (responseData["status"] === "rejected") {
					if (responseData["reason"] === "max_limit_reached") {
						failAlertRef.current?.show("Article generation request failed. " +
							"You have reached your max article generation limit for the month.");
					} else {
						failAlertRef.current?.show(`Article generation request failed. Error ID: ${responseData["reason"]}`);
					}
				} else {
					failAlertRef.current?.show(
						`Article generation request returned unknown status ${responseData["status"]}. Please contact us if there's any issue.`
					);
				}
				setInitialSortingState(initialSortingState);
			},
			onError: (error: Error) => {
				console.error(error);
				setRequestModalActive(false);
				failAlertRef.current?.show(`Article generation request failed. Please try again later`)
				setTimeout(() => {
					failAlertRef.current?.close();
				}, 5000);
			}
		});
	}

	function postToBlogHandler(articleUID: string) {
		failAlertRef.current?.close();
		successAlertRef.current?.close();

		setModalText("Posting to your website blog. Please wait...");
		setRequestModalActive(true);
		postArticle.mutate({articleUID: articleUID, selectedIntegration: selectedIntegration}, {
			onSuccess: () => {
				setRequestModalActive(false);
				revalidate();
				successAlertRef.current?.show(`Article ${articleUID} was posted to your site successfully!`);
			},
			onError: () => {
				setRequestModalActive(false);
				failAlertRef.current?.show(
					"Oops! Something went wrong. Check your Email for more details or contact us if the issue persists."
				);
			}
		})
	}

	function setIntegrationAndHideDropDownContent(integrate: string){
		setSelectedIntegration(integrate);
		setOpenDropdown("");
	}

	// ================== Generate table data and render AbunTable component ==================
	const columnHelper = createColumnHelper<ArticleTitle>();

	const columnDefs: ColumnDef<any, any>[] = [
		columnHelper.accessor((row: ArticleTitle) => row.articleTitle, {
			id: 'articleTitle',
			header: "Article Title",
			cell: info => info.getValue(),
			enableGlobalFilter: true,
			enableSorting: false,
		}),
		columnHelper.accessor((row: ArticleTitle) => row.wordCount, {
			id: 'wordCount',
			header: "Words",
			cell: (info) => {
				if (info.getValue() === null || info.getValue() === 0) {
					return "---";
				} else {
					return info.getValue();
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		}),
		columnHelper.display({
			id: 'generate_articles',
			header: () => "Action",
			cell: cellProps => {
				let processing: boolean = cellProps.row.original.isProcessing;
				let generated: boolean = cellProps.row.original.isGenerated;
				let failed: boolean = cellProps.row.original.isFailed;
				if (generated) {
					return <LinkButton linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
						text={"View"}
						type={"success"}
						width={"100px"}
						outlined={true}
						additionalClassList={["is-small", "more-rounded-borders"]} />
				} else if (processing) {
					return <LinkButton linkTo={`/articles/edit/${cellProps.row.original.articleUID}/`}
						text={"Generating..."}
						type={"primary"}
						width={"100px"}
						outlined={true}
						additionalClassList={["is-small", "more-rounded-borders"]} />
				} else if (failed) {
					return <GenericButton text={"Failed. Retry"}
						type={"danger"}
						width={"100px"}
						disable={generateArticle.isLoading}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => {
							generateArticleHandler(cellProps.row.original.articleUID);
						}} />
				} else {
					return <div data-tooltip-id="generate-article" data-tooltip-content="Verify email to create article">
						<GenericButton text={"Create Article"}
							type={"primary"}
							width={"100px"}
							outlined={true}
							disable={generateArticle.isLoading || !userVerified}
							additionalClassList={["is-small", "more-rounded-borders"]}
							clickHandler={() => {
								generateArticleHandler(cellProps.row.original.articleUID);
							}} />
						{!userVerified && <Tooltip id="generate-article" />}
					</div>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center',
			}
		}),
		columnHelper.display({
			header: "Publish",
			id: 'post_article',
			cell: cellProps => {
				let posted: boolean = cellProps.row.original.isPosted;
				let generated: boolean = cellProps.row.original.isGenerated;
				let postedTo: string = cellProps.row.original.postedTo;
				let archived: boolean = cellProps.row.original.isArchived;
				let articleUID: string = cellProps.row.original.articleUID;
				if (posted) {
					return <>
						<a className={"view-on-blog-link"} href={cellProps.row.original.postLink} data-tooltip-id="my-tooltip" target="_blank" rel="noreferrer" data-tooltip-content="View on Blog" >
							<img className={"image"} src={postedTo === "wordpress" ? wordpressIconSuccess : webflowIconSuccess} alt={postedTo === "wordpress" ? "wordpress-icon" : "webflow-icon"} />
						</a>
						<Tooltip id="my-tooltip" />
					</>
				} else if (generated) {
					return <>
						<div className="publish-container is-flex is-align-items-center is-justify-content-center">
							<button style={{marginLeft: "18px"}} className={"publish-to-blog-btn is-flex is-align-items-center is-justify-content-center"} data-tooltip-id="publish-to-blog" data-tooltip-content={allIntegrations.length > 0 ? "Publish to Blog" : "Connect & Publish Now"}
								onClick={() => {
									if (allIntegrations.length > 0) {
										postToBlogHandler(articleUID);
									} else {
										setIntegrationModalActive(true);
									}
								}}>
								<img className={"image"} src={allIntegrations.length === 0 ? articleIntegrationIcon : selectedIntegration === "webflow" ? webflowIconPrimary : wordpressIconPrimary} alt={selectedIntegration === "webflow" ? "webflow-icon" : "wordpress-icon"} />
							</button>
							{allIntegrations.length > 0 && 
								<div className="dropdown">
									<button className={`dropdown-icon ${openDropdown === articleUID ? "rotate" : ""}`} onClick={() => setOpenDropdown(openDropdown === articleUID ? "" : articleUID)}>&#9662;</button>
									<div className={`dropdown-content ${openDropdown === articleUID ? "show" : ""}`}>
										{
											allIntegrations.map((integration, index) => (
												<p key={index} onClick={() => setIntegrationAndHideDropDownContent(integration)}>{integration}</p>
											))
										}
									</div>
								</div>
							}
						</div>
						<Tooltip id="publish-to-blog" />
					</>
				} else if (archived) {
					return <GenericButton text={"Unarchive"}
						type={"success"}
						disable={archiveSelectedArticles.isLoading}
						additionalClassList={["is-small", "more-rounded-borders"]}
						clickHandler={() => {
							archiveSelectedArticles.mutate({ articlesUID: [cellProps.row.original.articleUID], archiveType: "unarchive" }, {
								onSuccess: () => {
									successAlertRef.current?.show("Article was unarchived successfully!");
									revalidate();
								},
								onError: () => {
									failAlertRef.current?.show("Failed to unarchive article. Please try again after some time.");
								}
							});
						}} />
				} else {
					return <></>
				}
			},
			enableGlobalFilter: false,
			meta: {
				align: 'center'
			}
		})
	]

	return (
		<div className={"keyword-project-container is-flex w-100 is-align-items-center is-justify-content-space-between is-flex-direction-column"}>
			<div className={"keyword-project-header"}>
				<svg className={"back-btn"} onClick={() => navigate(pageURL['keywordResearch'] + "?keywordProjectId=" + pageData.keyword_project_id)} stroke="#bcbcbc" fill="#bcbcbc" width="28" height="24" viewBox="0 0 28 24">
					<path d="M27.5 12H2M2 12L13 1M2 12L13 23" stroke="black" strokeOpacity="0.5" strokeWidth="2" />
				</svg>
			</div>
			{/* ------------------------------ INTEGRATION MODAL ------------------------------ */}
			<AbunModal active={integrationModalActive}
				headerText={""}
				closeable={true}
				hideModal={() => {
					setIntegrationModalActive(false)
				}}>
				<div className={"has-text-centered"}>
					<h1 className={"is-size-3"}>Publish articles to your blog page!</h1>
					<p className={"mt-4"}>
						Start publishing articles to your blog page by setting up an Integration for your website.
						You can find it under <b>Settings</b> {">"} <b>Integration & Scheduling</b>
					</p>
					<LinkButton text={"Go to Settings"}
						linkTo={pageURL['settings'] + "?tab=integration"}
						type={"primary"}
						additionalClassList={["mt-5"]} />
				</div>
			</AbunModal>

			{/* ------------------------------ ONGOING REQUEST MODAL ------------------------------ */}
			<AbunModal active={requestModalActive || createCustomTitleForKeyword.isLoading}
				headerText={""}
				closeable={false}
				hideModal={() => {
					setRequestModalActive(false)
				}}>
				<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
					<img className={"image"} src={loadingIcon} alt="loading" />
				</div>
				<p className={"is-size-4 has-text-centered mb-4"}>{modalText}</p>
			</AbunModal>

			<div className={"is-flex is-flex-direction-column is-align-items-center is-justify-content-center"} style={titlesGenerated ? { height: "auto", width: "100%" } : { height: "70vh", width: "100%" }}>
				<div className="keyword-title-table-header">
					<h1>Choose Title to create Article for</h1>
					<h2>Keyword: {pageData.keyword}</h2>
					<p>
						Total Keyword Traffic: {pageData.keywordTraffic}
						<img
							loading="lazy"
							width="16"
							srcSet={`https://flagcdn.com/32x24/${pageData.locationIsoCode.toLowerCase()}.png 2x`}
							src={`https://flagcdn.com/16x12/${pageData.locationIsoCode.toLowerCase()}.png`}
							alt={pageData.locationIsoCode}
						/>
					</p>
				</div>

				{/* Below TSX component conditionally renders UI elements based on the state of article title generation.
				1. If titles have not been generated, it shows buttons to either generate article titles or write a custom title.
					- The "Generate Article Titles" button triggers an asynchronous operation to generate titles based on a keyword hash.
					- The "Write Custom Title" button opens a modal for custom title input.
				2. If loading or no table data is present, it displays a loading indicator.
				3. On error, it shows an error message.
				4. If titles are generated and data is available, it displays a table (`AbunTable`) of article titles with sorting and pagination options, and a button to write a custom title. 
				*/}
				{
					!titlesGenerated ? // Display buttons to generate article titles or write custom title if titles have not been generated
					<div className={"is-flex is-justify-content-center mt-4"}>
						<GenericButton text={"Generate Article Titles"}
							type={"primary"}
							disable={titlesForKeywordMutation.isLoading}
							additionalClassList={["is-small"]}
							clickHandler={() => {
								setModalText("Generating article titles. Please wait...");
								setRequestModalActive(true);
								titlesForKeywordMutation.mutate({
									keyword_hash: pageData.keywordHash,
									location: pageData.locationIsoCode.toLowerCase()
								}, {
									onSuccess: () => {
										setRequestModalActive(false);
										setTitlesGenerated(true);
										revalidate();
										successAlertRef.current?.show("New Titles have been generated successfully!");
										setTimeout(() => {
											successAlertRef.current?.close();
										}, 5000);
									},
									onError: ((error: Error) => {
										setRequestModalActive(false);
										if (error.message) {
											failAlertRef.current?.show(
												`Failed to generate new titles. ${error.message}`
											);
										} else {
											failAlertRef.current?.show(
												`Failed to generate new titles. Please try again later.`
											);
										}
										setTimeout(() => {
											failAlertRef.current?.close();
										}, 5000);
									})
								});
							}}
						/><span className="mr-5 ml-5">or</span>
						<GenericButton text={"Write Custom Title"}
							type={"primary"}
							disable={createCustomTitleForKeyword.isLoading}
							clickHandler={() => setShowCreateCustomTitleModal(true)}
							additionalClassList={["is-small"]} />
					</div>
					// : error ? // Display error message if an error occurs
					// <div>
					// 	<p>Error! Failed to load data. Please try again later.</p>
					// </div>
					// : (isLoading || tableData.length === 0) ? // Display loading indicator if data is loading or no data is present
					// <div className={"loadingDataCard mt-4"} style={{ width: "100%" }}>
					// 	<div className={"card-content"}>
					// 		<div className={"content is-flex is-justify-content-center"}>
					// 			<p style={{ textAlign: "center", fontSize: "1.3rem" }}>
					// 				Loading Data...<Icon iconName={"spinner"} marginClass={"ml-5"} />
					// 			</p>
					// 		</div>
					// 	</div>
					// </div>
					: // Display the table of article titles if data is available
					<AbunTable tableContentName={"Article Titles"}
						id="article-titles-table"
						tableData={tableData}
						columnDefs={columnDefs}
						pageSizes={pageSizes}
						initialPageSize={pageSizes[6]}
						enableSorting={true}
						defaultSortingState={initialSortingState}
						noDataText={"No articles found. Generate more titles to create articles."}
						buttons={[
							{
								text: "Write Custom Title",
								type: "primary",
								isDisabled: false,
								clickHandler: () => setShowCreateCustomTitleModal(true),
								extraClassName: "is-small is-justify-content-space-between",
							}
						]}
					/>
				}
			</div>

			{/* ------------------------------ CREATE CUSTOM TITLE MODAL ------------------------------ */}
			<AbunModal active={showCreateCustomTitleModal}
				headerText={"Write a custom title keyword: " + pageData.keyword}
				closeable={true}
				hideModal={() => {
					setShowCreateCustomTitleModal(false)
				}}>
				<div className={"has-text-centered"}>
					<input type="text" className={"input "} placeholder={"Enter your custom title here..."} onChange={(e) => {
						setCustomTitle(e.target.value);
					}} />
					<AbunButton type={"success"}
						className={"mt-4"}
						disabled={createCustomTitleForKeyword.isLoading}
						clickHandler={() => {
							setShowCreateCustomTitleModal(false);
							createCustomTitleForKeyword.mutate({
								keyword: pageData.keyword,
								keywordHash: pageData.keywordHash,
								customArticleTitle: customTitle
							}, {
								onSuccess: () => {
									setTitlesGenerated(true);
									revalidate();
									successAlertRef.current?.show("Custom title added successfully!");
									setTimeout(() => {
										successAlertRef.current?.close();
									}, 5000);
								},
								onError: () => {
									failAlertRef.current?.show("Failed to add custom title. Please try again after some time.");
									setTimeout(() => {
										failAlertRef.current?.close();
									}, 5000);
								}
							});
						}}>
						Proceed
					</AbunButton>
				</div>
			</AbunModal>

			<SuccessAlert ref={successAlertRef} />
			<ErrorAlert ref={failAlertRef} />
		</div>
	)
}
