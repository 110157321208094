import {useMutation} from "@tanstack/react-query";
import {downloadArticle, retryFn} from "../utils/api";

interface DownloadFileProps {
	downloadPath: string
	getFileName: () => string
	onError?: (error: string) => void
}

export const useDownloadFile = (props: DownloadFileProps) => {
	// ----------------- STATES -----------------
	// const [fileUrl, setFileUrl] = useState<string>("");
  // const [fileName, setFileName] = useState<string>("");

	// ----------------- MUTATIONS -----------------
	const downloadArticleMut = useMutation({
		mutationKey: ["downloadArticle", props.downloadPath],
		mutationFn: downloadArticle,
		cacheTime: 0,
		retry: retryFn,
		onSuccess: data => {
			try {
				const fileUrl = URL.createObjectURL(data['data']);
				const fileName: string = props.getFileName();

				console.log(data);
				console.log(fileUrl);
				console.log(fileName)

				const element = document.createElement("a");
				element.href = fileUrl;
				element.download = fileName;
				document.body.appendChild(element);
				element.click()
				document.body.removeChild(element);
				URL.revokeObjectURL(fileUrl);

			} catch (e) {
				props.onError && props.onError((e as Error).message);
			}
		},
		onError: error => {
			props.onError && props.onError(error);
		}
	});

	const download = () => {
		downloadArticleMut.mutate(props.downloadPath);
	}

	return {download}
}
