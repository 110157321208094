import "./CheckoutSuccess.scss";
import greenCheck from "../../assets/images/icons/green-checkmark-circle.svg";
import {Link, useSearchParams} from "react-router-dom";
import {pageURL} from "../routes";
import {useEffect} from "react";
import loadingIcon from "../../assets/images/loadingIcon.webp"
import {useQuery} from "@tanstack/react-query";
import {checkoutSuccessQuery} from "../../utils/api";
import ReactGA from "react-ga4";

interface CheckoutSuccessData {
	amount : number
	charge_id : string
	currency: string
	customer_id: string
	plan_name: string
}

export default function CheckoutSuccess() {
	// --------------------- QUERY PARAMETERS ---------------------
	let [searchParams] = useSearchParams();
	let checkoutSessionID = searchParams.get("session_id");

	// --------------------- QUERIES ---------------------
	const {isLoading, error, data} = useQuery(checkoutSuccessQuery(checkoutSessionID));

	useEffect(() => {
		document.title = "Checkout Successful | Abun";
		if(!isLoading && (data as any).data) {
				ReactGA.gtag('event', 'conversion', {
					'send_to': 'AW-11453495091/U8jKCJC35IkZELPuudUq',
					'value': ((data as any).data as CheckoutSuccessData).amount,
					'currency': ((data as any).data as CheckoutSuccessData).currency
			  }
			);

			// checkout tracking Partnero script
			const checkoutTrackingScript = document.createElement('script');
			checkoutTrackingScript.innerHTML = `
			  po('transactions', 'create', {
				 data: {
					key: '${((data as any).data as CheckoutSuccessData).charge_id}',
					amount: ${((data as any).data as CheckoutSuccessData).amount},
					amount_units: '${((data as any).data as CheckoutSuccessData).currency}',
					customer: {
					  key: '${((data as any).data as CheckoutSuccessData).customer_id}'
					}
				 },
				 options: {
					create_customer: true
				 }
			  });
			`;
			document.head.appendChild(checkoutTrackingScript);
	  
			return () => {
			  // Remove the tracking script when the component unmounts
			  document.head.removeChild(checkoutTrackingScript);
			};
		}	
	}, [data, isLoading]);


	if (isLoading) {
		return (
			<div className={"checkout-success-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
								<img className={"image"} src={loadingIcon} alt="loading" />
							</div>
							<h2 className={"is-size-4 has-text-centered"}>Fetching Payment Details. Please Wait...</h2>
						</div>
					</div>
				</div>
			</div>
		)

	} else if (error) {
		return (
			<div className={"checkout-success-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<p className={"has-text-centered"}>Oops! Something went wrong :(</p>
							<p className={"has-text-danger has-text-centered mt-4"}>{error.toString()}</p>
						</div>
					</div>
				</div>
			</div>
		)

	} else {
		return (
			<div className={"checkout-success-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<img src={greenCheck}
									 alt="green_checkmark"
									 width={92}
									 height={92}
									 className={"is-block ml-auto mr-auto mb-5"}/>
							<h2 className={"is-size-4 has-text-centered"}>Payment Successful!</h2>
							<p className={"has-text-centered mt-3"}>
								You are now subscribed to Monthly <b>{(data as any)['data']['plan_name']}</b> plan.
							</p>
							<Link to={pageURL['keywordResearch']}
										className={"is-block mt-6 ml-auto mr-auto button is-primary"}>
								Proceed to Abun
							</Link>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
