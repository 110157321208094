import React, { CSSProperties, ReactNode, useMemo, useState } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';  // Tippy.js default styles
import "./AbunButton.scss";

interface AbunButtonProps {
	children: ReactNode;
	type: "primary" | "success" | "danger" | "warning" | "light" | "none";
	className?: string;
	style?: CSSProperties;
	disabled?: boolean;
	progress?: number;
	progressColor?: string;
	tooltip?: string;
	clickHandler: () => void;
}

function AbunButton(props: AbunButtonProps) {
	const [showTooltip, setShowTooltip] = useState(false);

	// Memoize the tooltip content to avoid re-renders
	const memoizedTooltip = useMemo(() => props.tooltip, [props.tooltip]);

	// Utility function to calculate text color based on progress
	function getTextColorBasedOnProgress() {
		const progressFactor = Math.min(Math.max(props.progress || 0, 0), 100) / 100;
		const lowRGB = hexToRGB(props.progressColor || "#23d160");
		const highRGB = hexToRGB("#FFFFFF");

		const r = Math.round(lowRGB.r + progressFactor * (highRGB.r - lowRGB.r));
		const g = Math.round(lowRGB.g + progressFactor * (highRGB.g - lowRGB.g));
		const b = Math.round(lowRGB.b + progressFactor * (highRGB.b - lowRGB.b));

		return `rgb(${r}, ${g}, ${b})`;
	}

	// Utility function to convert hex color code to RGB
	function hexToRGB(hex: string) {
		const bigint = parseInt(hex.replace("#", ""), 16);
		return {
			r: (bigint >> 16) & 255,
			g: (bigint >> 8) & 255,
			b: bigint & 255,
		};
	}

	// Define button style based on progress
	const progressStyle = props.progress
		? {
			background: `linear-gradient(to right, ${props.progressColor} ${props.progress}%, transparent ${props.progress}%)`,
			color: getTextColorBasedOnProgress(),
		}
		: {};

	const button = (
		<div
			onMouseEnter={() => props.tooltip && setShowTooltip(true)}
			onMouseLeave={() => setShowTooltip(false)}
		>
			<button
				className={`button is-${props.type} ${props.className}`}
				style={{ ...props.style, ...progressStyle }}
				disabled={props.disabled}
				onClick={props.clickHandler}
			>
				{props.children}
			</button>
		</div>
	);

	// Always render Tippy, but control visibility
	return (
		<Tippy
			content={memoizedTooltip}
			visible={showTooltip}
			placement="top"
			delay={[100, 0]}
			hideOnClick={false}
		>
			{button}
		</Tippy>
	);
}

export default React.memo(AbunButton);
