import "./Icon.scss";

import atmCardIcon from "../../assets/images/icons/atm-card-icon.png";
import bookIcon from "../../assets/images/icons/book-icon.png";
import downloadIcon from "../../assets/images/icons/download-icon.png";
import homeIcon from "../../assets/images/icons/home-icon.png";
import userCircleIcon from "../../assets/images/icons/user-circle-icon.png";
import webGlobeIcon from "../../assets/images/icons/web-globe-icon.png";
import defaultIcon from "../../assets/images/icons/default-missing-icon.png";
import plusIcon from "../../assets/images/icons/plus-icon.png";
import greenCheckmarkCircle from "../../assets/images/icons/green-checkmark-circle.svg";
import contentPlanIcon from "../../assets/images/icons/content-plan-icon.svg";
import paragraphIcon from "../../assets/images/icons/paragraph-icon.png";
import spinnerIcon from "../../assets/images/icons/spinner.svg";
import chevronLeftIcon from "../../assets/images/icons/chevron-left.png";
import chevronRightIcon from "../../assets/images/icons/chevron-right.png";
import deleteIcon from "../../assets/images/icons/delete-icon.svg";
import deleteCircleIcon from "../../assets/images/icons/delete-circle.svg";
import cloudUploadIcon from "../../assets/images/icons/cloud-upload.svg";
import gearIcon from "../../assets/images/icons/gear-icon.svg";
import calendarSearchIcon from "../../assets/images/icons/calendar-search-icon.svg";
import questionSolidIcon from "../../assets/images/icons/help-question-solid-icon.svg";
import editIcon from "../../assets/images/icons/edit-icon.svg";
import logoutIcon from "../../assets/images/icons/logout.svg";
import plusCircleIcon from "../../assets/images/icons/plus-circle.svg";
import floppyDiskIcon from "../../assets/images/icons/floppy-disk.svg";
import hamburgerIcon from "../../assets/images/icons/hamburger.svg";
import customerSupport from "../../assets/images/icons/customer-support.svg";
import trashIcon from "../../assets/images/icons/trash-icon.svg";
import wordpressBlue from "../../assets/images/icons/wordpress-logo-blue.svg";
import roadIcon from "../../assets/images/icons/roadmap.svg";
import archiveBox from "../../assets/images/icons/archive-box.svg";
import inboxPost from "../../assets/images/icons/inbox-post.svg";
import newsPaper from "../../assets/images/icons/news-paper.svg";
import arrowRight from "../../assets/images/icons/arrow-right.svg";
import refreshIcon from "../../assets/images/icons/refresh-icon.svg";
import webflowBlue from "../../assets/images/icons/webflow-logo-blue.svg";
import integrationIcon from "../../assets/images/icons/integration-icon.svg";
import lightingBlack from "../../assets/images/icons/lighting-black.svg";
import lighting from "../../assets/images/icons/lighting.svg";
import lightingOff from "../../assets/images/icons/lighting-off.svg"
import inboxPostBlack from "../../assets/images/icons/inbox-post-black.svg"
import wixBlue from "../../assets/images/icons/wix-logo-blue.svg"
import webflowGreen from "../../assets/images/icons/webflow-logo-green.svg"
import wordpressGreen from "../../assets/images/icons/wordpress-logo-green.svg"
import wixGreen from "../../assets/images/icons/wix-logo-green.svg"
import googleSearchConsoleLogo from "../../assets/images/google-search-console.png"

export type IconNames = "atm" | "book" | "download" | "home" | "user-circle" | "web-globe" | "plus"
	| "green-checkmark-circle" | "content-plan" | "paragraph" | "spinner" | "spinner-static" | "chevron-left"
	| "chevron-right" | "delete" | "cloud-upload" | "delete-circle" | "gear" | "calendar-search" | "question-solid"
	| "edit" | "logout" | "plus-circle" | "floppy-disk" | "hamburger" | "customer-support" | "wordpress-blue" | "trash" | "road"
	| "archive" | "post-article" | "generate-article" | "arrow-right" | 'refresh' | "webflow-blue" | "integration" | "lighting"
	| "lighting-black" | "lighting-off" | "post-article-black" | "wix-blue" | "wordpress-green" | "webflow-green" | "wix-green" |
	"google";

export interface IconProps {
	iconName: IconNames;
	width?: string
	height?: string
	marginClass?: string
	additionalClasses?: Array<string>
	onClick?: () => void
}

export default function Icon(props: IconProps) {
	let iconWidth = "1.4em";
	let iconHeight = "auto";
	let marginClass = "m-0";


	if (props.width) iconWidth = props.width;
	if (props.height) iconHeight = props.height;
	if (props.marginClass) marginClass = props.marginClass;

	let classList = [marginClass]
	if (props.additionalClasses) classList.push(...props.additionalClasses);

	switch (props.iconName) {
		case "atm":
			return (<img src={atmCardIcon}
									 className={classList.join(' ')}
									 alt="atm"
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "book":
			return (<img src={bookIcon}
									 alt="book"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "download":
			return (<img src={downloadIcon}
									 alt="download"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "home":
			return (<img src={homeIcon}
									 alt="home"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "user-circle":
			return (<img src={userCircleIcon}
									 alt="user-circle"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "web-globe":
			return (<img src={webGlobeIcon}
									 alt="web-globe"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "plus":
			return (<img src={plusIcon}
									 alt="plus"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "green-checkmark-circle":
			return (<img src={greenCheckmarkCircle}
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 alt="green-checkmark-circle"
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "content-plan":
			return (<img src={contentPlanIcon}
									 alt="content-plan"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "paragraph":
			return (<img src={paragraphIcon}
									 alt="paragraph"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "spinner":
			return (<img src={spinnerIcon}
									 alt="spinner"
									 className={`spinner-spin ${classList.join(' ')}`}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)

		case "spinner-static":
			return (<img src={spinnerIcon}
									 alt="spinner"
									 className={`${classList.join(' ')}`}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)

		case "chevron-left":
			return (<img src={chevronLeftIcon}
									 alt="chevron-left"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "chevron-right":
			return (<img src={chevronRightIcon}
									 alt="chevron-right"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "delete":
			return (<img src={deleteIcon}
									 alt="delete"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "delete-circle":
			return (<img src={deleteCircleIcon}
									 alt="delete-circle"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "cloud-upload":
			return (<img src={cloudUploadIcon}
									 alt="cloud-upload"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "gear":
			return (<img src={gearIcon}
									 alt="gear"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "calendar-search":
			return (<img src={calendarSearchIcon}
									 alt="calendar-search"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "question-solid":
			return (<img src={questionSolidIcon}
									 alt="question-solid"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "edit":
			return (<img src={editIcon}
									 alt="edit"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)	 
		case "logout":
			return (<img src={logoutIcon}
									 alt="logout"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "plus-circle":
			return (<img src={plusCircleIcon}
									 alt="plus"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "floppy-disk":
			return (<img src={floppyDiskIcon}
									 alt="floppy"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "hamburger":
			return (<img src={hamburgerIcon}
									 alt="hamburger"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "customer-support":
			return (<img src={customerSupport}
									 alt="customer-support"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "trash":
			return (<img src={trashIcon}
									 alt="trash"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "wordpress-blue":
			return (<img src={wordpressBlue}
									 alt="wordpress-logo-blue"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "road":
			return (<img src={roadIcon}
									 alt="roadmap"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle", borderRadius: "50%"}}/>)
		case "archive":
			return (<img src={archiveBox}
									 alt="archive"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle", borderRadius: "50%"}}/>)
		case "generate-article":
			return (<img src={newsPaper}
									 alt="generate-article"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle", borderRadius: "50%"}}/>)
		case "post-article":
			return (<img src={inboxPost}
									 alt="post-article"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle", borderRadius: "50%"}}/>)
		case "post-article-black":
			return (<img src={inboxPostBlack}
									 alt="post-article"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle", borderRadius: "50%"}}/>)
		case "arrow-right":
			return (<img src={arrowRight}
									 alt="arrow-right"
									 className={classList.join(' ')}
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle", borderRadius: "50%"}}/>)
		case "refresh":
			return (<img src={refreshIcon}
							alt="refresh"
							className={classList.join(' ')}
							onClick={props.onClick}
							style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "webflow-blue":
			return (<img src={webflowBlue}
				alt="webflow-logo-blue"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "integration":
			return (<img src={integrationIcon}
				alt="integration-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "lighting":
			return (<img src={lighting}
				alt="lighting-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "lighting-black":
			return (<img src={lightingBlack}
				alt="lighting-black-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "lighting-off":
			return (<img src={lightingOff}
				alt="lighting-off-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "wix-blue":
			return (<img src={wixBlue}
				alt="wix-blue-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "wordpress-green":
			return (<img src={wordpressGreen}
				alt="wordpress-green-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "webflow-green":
			return (<img src={webflowGreen}
				alt="webflow-green-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "wix-green":
			return (<img src={wixGreen}
				alt="wix-green-logo"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		case "google":
			return (<img src={googleSearchConsoleLogo}
				alt="google"
				className={classList.join(' ')}
				onClick={props.onClick}
				style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
		default:
			return (<img src={defaultIcon}
									 alt="default"
									 onClick={props.onClick}
									 style={{width: iconWidth, height: iconHeight, verticalAlign: "middle"}}/>)
	}
}
