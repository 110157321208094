import greenCheck from "../../assets/images/icons/green-checkmark-circle.svg";

import {Link, useParams} from "react-router-dom";
import {pageURL} from "../routes";
import loadingIcon from "../../assets/images/loadingIcon.webp"
import {useQuery} from "@tanstack/react-query";
import {accountEmailVerificationQuery} from "../../utils/api";
import AuthPageNavbar from "../../components/AuthPageNavbar/AuthPageNavbar";

import "./AccountEmailVerification.scss";
import { useEffect } from "react";

export default function AccountEmailVerification() {
	// --------------------- QUERY PARAMETERS ---------------------
	const {token} = useParams();

	// --------------------- QUERY ---------------------
	const {isLoading, error} = useQuery(accountEmailVerificationQuery(token));

	useEffect(() => {
		if(!isLoading && !error) {
			// set user-email-verified in session storage on successful verification
			sessionStorage.setItem("user-email-verified", "true");
			// console.log("set user-email-verified in session storage");
		}
	}, [isLoading, error]);
	

	if (isLoading) {
		return (
			<div className={"account-email-verification-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<div className={"loadingData w-100 is-flex is-justify-content-center is-align-items-center"}>
								<img className={"image"} src={loadingIcon} alt="loading" />
							</div>
							<h2 className={"is-size-4 has-text-centered"}>Verifying your account. Please Wait...</h2>
						</div>
					</div>
				</div>
			</div>
		)

	} else if (error) {
		return (
			<>
			{/* ----------------------- NAVBAR ----------------------- */}
			{/* <AuthPageNavbar/> */}
			<div className={"account-email-verification-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<p className={"has-text-centered"}>Oops! Something went wrong :(</p>
							<p className={"has-text-danger has-text-centered mt-4"}>{error.toString()}</p>
						</div>
					</div>
				</div>
			</div>
			</>
		)

	} else {
		return (
			<>
			{/* ----------------------- NAVBAR ----------------------- */}
			{/* <AuthPageNavbar/> */}
			<div className={"account-email-verification-container"}>
				<div className={"card"}>
					<div className={"card-content"}>
						<div className={"content success-card-content"}>
							<img src={greenCheck}
									 alt="green_checkmark"
									 width={92}
									 height={92}
									 className={"is-block ml-auto mr-auto mb-5"}/>
							<h2 className={"is-size-4 has-text-centered has-text-weight-bold"}>You are all set to double your Organic Traffic!</h2>
							<p className={"has-text-centered mt-3"}>
								Your email has been verified.
							</p>
							<Link to={pageURL['keywordResearch']}
										style={{maxWidth: "200px"}}
										className={"is-block mt-6 ml-auto mr-auto button is-primary"}>
								Go to Keyword Research
							</Link>
						</div>
					</div>
				</div>
			</div>
			</>
		)
	}
}
